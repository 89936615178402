// portal、partner、services域名前缀
export const DOMAIN_PREFIX_PORTAL = "portal";
export const DOMAIN_PREFIX_PARTNER = "partner";
export const DOMAIN_PREFIX_SERVICE = "service";

// portal对应域名
export const DOMAIN_DEV = "portal.dev.test-aspendigital.co";
export const DOMAIN_BETA = "portal.beta.test-aspendigital.co";
export const DOMAIN_PROD = "portal.aspendigital.co";
export const DOMAIN_RED = "portal-red.beta.test-aspendigital.co";
export const DOMAIN_GREEN = "green-portal.aspendigital.co";

// adgm对应域名
export const ADGM_DOMAIN_PROD = "portal-ad.aspendigital.co";
export const ADGM_DOMAIN_BETA = "portal-ad-beta.test-aspendigital.co";
export const ADGM_DOMAIN_PROD_PARTNER = "partner-ad.aspendigital.co";
export const ADGM_DOMAIN_BETA_PARTNER = "partner-ad-beta.test-aspendigital.co";
export const ADGM_DOMAIN_PROD_MANAGER = "service-ad.aspendigital.co";
export const ADGM_DOMAIN_BETA_MANAGER = "service-ad-beta.test-aspendigital.co";

// 代理商对应域名
export const DOMAIN_DEV_PARTNER = "partner.dev.test-aspendigital.co";
export const DOMAIN_BETA_PARTNER = "partner.beta.test-aspendigital.co";
export const DOMAIN_PROD_PARTNER = "partner.aspendigital.co";
export const DOMAIN_RED_PARTNER = "partner-red.beta.test-aspendigital.co";
export const DOMAIN_GREEN_PARTNER = "green-partner.aspendigital.co";

//管理员对应域名
export const DOMAIN_DEV_MANAGER = "service.dev.test-aspendigital.co";
export const DOMAIN_BETA_MANAGER = "service.beta.test-aspendigital.co";
export const DOMAIN_PROD_MANAGER = "service.aspendigital.co";
export const DOMAIN_RED_MANAGER = "service-red.beta.test-aspendigital.co";
export const DOMAIN_GREEN_MANAGER = "green-service.aspendigital.co";

// SAAS对应域名
export const DOMAIN_SAAS_DEV = "dev-asportal.co";
export const DOMAIN_SAAS_BETA = "beta-asportal.co";
export const DOMAIN_SAAS_PROD = ".asportal.co";

export const BUCKET_PROD = "http://portal.aspendigital.co/tradingview-react-javascript/index.html";
export const BUCKET_BETA =
  "http://portal.beta.test-aspendigital.co/tradingview-react-javascript/index.html";
export const BUCKET_DEV =
  "http://portal.dev.test-aspendigital.co/tradingview-react-javascript/index.html";
export const BUCKET_BETA_RED =
  "http://portal-red.beta.test-aspendigital.co/tradingview-react-javascript/index.html";
