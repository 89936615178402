import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStakingItem } from "@aspen/model";
import { RootState } from "../store";

interface IinitialState {
  yieldStakingList: IStakingItem[]; // staking
}

const initialState: IinitialState = {
  yieldStakingList: [] // staking
};

// TODO
export const yieldSlice: any = createSlice({
  name: "yield",
  initialState,
  reducers: {
    // yield staking
    updateYieldStakingList: (state, action: PayloadAction<IStakingItem[]>) => {
      state.yieldStakingList = action.payload;
    },
    clearAllYield: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    }
  }
});

export const yieldStakingList = (state: RootState) => state.yield.yieldStakingList;

export const { updateYieldStakingList, clearAllYield } = yieldSlice.actions;

export default yieldSlice.reducer;
