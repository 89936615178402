/* eslint-disable no-bitwise */
import {
  AGENT_OPERATING_TOKEN,
  BENCHMARK_CURRENCY,
  BENCHMARK_CURRENCY_SYMBOL,
  BTC_USDC,
  BTC_USDT,
  CUSTOMER_ID,
  FORMATWEBP,
  GA_EVENT_NAME,
  getConfigs,
  host,
  INTELLIGENCE_PATHS,
  JWT_TOKEN,
  KEY_COMPLIANCE_TAG,
  LANGUAGE,
  OPERATE_CUSTOMER_ID,
  REFERENCE_CURRENCY,
  REG_EXP_NUMBER,
  REG_EXP_POSITIVE_NUMBER
} from "../config/hosts";
import { formatBugsnagMessage } from "./bugsnag";
import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import md5 from "md5";
import type {
  IAutoInvestCoinItem,
  IChartDataValidate,
  ICountriesData,
  IEnvConfig,
  IEnvs,
  IGenerateDataCacheKey,
  ILanguageType,
  TSystem
} from "@aspen/model";
import { BRAND } from "@aspen/model";
import { userCountriesData } from "@aspen/assets/offlineData/usersCountries";
import { reportEvent } from "./logUtils";
import UAParser from "ua-parser-js";

interface rateModel {
  denomination: string;
  rate: number;
  percent: string;
}

/**
 * 数字千分位化
 * 每隔三位加逗号
 * @param number 要处理的数字
 * @returns {string}
 */
export const numberToThousands: (number: number | string) => number | string = (
  number
): number | string => {
  if (!number) {
    return number;
  }
  number = scientificToNumber(number).toString();
  const list = number.split(".");
  const prefix = list[0].startsWith("-") ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
};

// 密码格式，数字+小写字母+大写字母+特殊符号，8位以上，不包含空格
export const passwordRegExp =
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;

/**
 * @description: 校验密码格式是否正确
 * @params {
 *  password：密码，
 *  message：格式或者长度错误提示，
 * }
 * @return {Promise}
 */
export const passwordValidator: (password: string, message: string) => Promise<any> = (
  // any 不修改，promise不强制数据结构
  password,
  message
) => {
  const lowerCasePassword = password.toLowerCase();
  // 校验password不包含password和test单词及其任意字母的大小写变体
  const regex = /^(?!.*\b(password|test)\b)/;

  if (!password || !regex.test(lowerCasePassword) || !passwordRegExp.test(password)) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

/**
 * @description: 校验确认密码格式以及是否和密码一致，重置密码和设置密码使用
 * @params {
 *  confirmPassword：确认密码，
 *  password：密码，
 *  message：格式或者长度错误提示，
 *  notMatch：两次密码不一致时的提示
 * }
 * @return {Promise}
 */
export const passwordConfirmValidator: (
  confirmPassword: string,
  password: string,
  message: string,
  notMatch: string
) => Promise<any> = (
  // 不修改，promise不强制数据结构
  confirmPassword,
  password,
  message,
  notMatch
) => {
  if (!confirmPassword) {
    return Promise.reject(new Error(message));
  }
  if (password !== confirmPassword) {
    return Promise.reject(new Error(notMatch));
  }
  const lowerCaseConfirmPassword = confirmPassword.toLowerCase();
  // 校验password不包含password和test单词及其任意字母的大小写变体
  const regex = /^(?!.*\b(password|test)\b)/;
  if (
    !confirmPassword ||
    password.length !== confirmPassword.length ||
    !regex.test(lowerCaseConfirmPassword) ||
    !passwordRegExp.test(confirmPassword)
  ) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

export const getQueryValue = (queryName: string, location?: string) => {
  const reg = new RegExp(`(^|&)${queryName}=([^&]*)(&|$)`, "i");
  const r = isClient && (location || window.location.search)?.substr(1).match(reg);
  if (r != null) {
    // @ts-ignore
    return decodeURI(r[2]);
  }
  return "";
};

/**
 * @description: 获取query参数对象
 * @params {}
 * @return Object {[key: string]: string}
 */
export const getQueryParams = () => {
  try {
    const params: Record<string, string> = {};
    if (isClient) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.forEach((value, key) => {
        params[key] = value;
      });
    }
    return params;
  } catch (error) {
    return {};
  }
};

/**
 * @description: 获取浏览器及设备信息
 * @params {}
 * @return Object {
 os: string,
 osVersion: string,
 browser: string,
 browserVersion: string,
 deviceType: string,
 deviceModel: string,
 deviceVendor: string,
 useAgent: string
 }
 */
export const getBrowserDeviceInfo = () => {
  try {
    const parser = new UAParser(navigator.userAgent);
    const os = parser.getOS();
    const browser = parser.getBrowser();
    const device = parser.getDevice();
    return {
      os: os?.name,
      osVersion: os?.version,
      browser: browser?.name,
      browserVersion: browser?.version,
      deviceType: device?.type,
      deviceModel: device?.model,
      deviceVendor: device?.vendor,
      useAgent: navigator.userAgent
    };
  } catch (error) {
    return {
      useAgent: navigator.userAgent
    };
  }
};

/**
 * 判断是否是邮箱格式
 * @param str
 * @returns {string}
 */
export const isEmail = (str: string) => {
  let string = str.replace(/\s|&nbsp;/g, ""); //先去除用户输入的无效字符
  let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  if (reg.test(string)) {
    return true;
  }
  return false;
};

/**
 * 时间戳转化为日期格式
 * @param num
 * @returns {string}
 */
export const transformTime = (timestamp = Number(new Date())) => {
  if (timestamp) {
    const time = new Date(timestamp);
    const y = time.getFullYear();
    const M = time.getMonth() + 1;
    const d = time.getDate();
    const h = time.getHours();
    const m = time.getMinutes();
    const s = time.getSeconds();
    return `${y}-${addZero(M)}-${addZero(d)} ${addZero(h)}:${addZero(m)}:${addZero(s)}`;
  }
  return "";
};
export const toLocaleTime: (
  time: string | number,
  format?: string,
  utcFormat?: string,
  timestamp?: boolean
) => string = (time, format, utcFormat, timestamp) => {
  format = format || "YYYY-MM-DD HH:mm:ss";
  timestamp = timestamp || false;
  dayjs.extend(utc);
  if (utcFormat) {
    return dayjs.utc(time.toString(), utcFormat).local().format(format);
  } else if (!timestamp) {
    return dayjs.utc(time).local().format(format);
  }
  return dayjs.utc(Number(time)).local().format(format);
};
/**
 * 格式化倒计时时间
 * @param time 单位:毫秒
 */
export const formatCountTime = (
  time: number
): {
  d: string | number;
  h: string | number;
  m: string | number;
  s: string | number;
} => {
  const go = (x: number) => {
    if (x < 10) {
      return `0${x}`;
    }
    return x;
  };

  const SECONDS = 1000;
  const MINUTES = 60 * SECONDS;
  const HOUR = 60 * MINUTES;
  const DAY = 24 * HOUR;

  const d = Math.floor(time / DAY);
  time = time % DAY;

  const h = Math.floor(time / HOUR);
  time = time % HOUR;

  const m = Math.floor(time / MINUTES);
  time = time % MINUTES;

  const s = Math.floor(time / SECONDS);

  return { d, h: go(h), m: go(m), s: go(s) };
};
/**
 * 时间戳转化为日期格式(没有时分秒)
 * @param num
 * @returns {string}
 */
export const transformDate = (timestamp = Number(new Date())) => {
  return transformTime(timestamp).split(" ")[0];
};
/**
 * 6位或者8位日期转换为YYYY-MM的形式
 * 入参为6位或者8位字符串
 * @param string
 * @returns {string}
 */
export const transformMonth = (time: string) => {
  try {
    if (typeof time !== "string") {
      return time;
    }
    if (time.length < 6) {
      return time;
    }
    const year = time.slice(0, 4);
    const month = time.slice(4, 6);
    return `${year}-${month}`;
  } catch (err) {
    return time;
  }
};

export const addZero = (m: number) => {
  return m < 10 ? `0${m}` : m;
};

export const toNonExponential = (num: number) => {
  try {
    const m = /\d(?:\.(\d*))?e([+-]\d+)/.exec(num.toExponential()) || [];
    const _len = (m[1] || "").length - Number(m[2]);
    return num.toFixed(Math.max(0, _len > 20 ? 20 : _len));
  } catch (e) {
    return num.toString();
  }
};

/**
 * 按照精度修复用户输入的金额，用于输入框金额输入
 * @param numOrString
 * @param digital
 * @returns {string}
 */
export const fixNumString: (numOrString: string | number, digital: number) => string = (
  numOrString,
  digital
) => {
  // if (numOrString?.toString().charAt(numOrString?.toString().length - 1) === ".") {
  //   let valueTemp = numOrString?.toString().slice(0, -1);
  //   valueTemp = valueTemp?.toString().replace(/0*(\d+)/, "$1");
  //   return valueTemp;
  // } else {
  //   return numOrString.toString();
  // }

  numOrString = numOrString ?? "";
  const numStr = String(numOrString);
  if (numOrString === ".") {
    return "0.";
  }
  if (numStr.indexOf(".") > 0) {
    // 位数大于0
    if (digital > 0) {
      const re = RegExp(`^(\\d+)\\.(\\d{1,${digital}}).*$`, "gim");
      // let str = numOrString.replace(/^(-)*(\d+)\.(\d{1,4}).*$/, '$1$2.$3');
      const str = numStr.replace(re, "$1.$2");
      const strArr = str.split(".");
      let ret = `${Number(strArr[0])}`;
      if (strArr[0].indexOf("-") > -1 && Number(strArr[0]) === 0) {
        ret = `-${ret}`;
      }
      if (strArr.length === 2) {
        ret = ret && strArr[1] ? `${ret}.${strArr[1]}` : "0"; // 单纯输入10. 的时候， 补0
      }
      return ret;
    }
    // 只能输入整数时
    // 单纯输入"10." 的时候去掉小数点
    return numStr.substr(0, numStr.indexOf("."));
  }
  return numStr;
};

/**
 * 保留指定位小数, placeorder在用
 * @param {numOrString} 需要处理的数值或者字符串
 * @param {digital} 指定的位数
 * @returns {string}
 */
export const formatFixNumString = (numOrString: number | string, digital?: number) => {
  const numberValue = Number(numOrString || 0);
  let numberStr = toNonExponential(numberValue) ?? 0;
  if (Number(digital) < 0) {
    digital = 0;
  } else if (Number(digital) > 20) {
    digital = 20;
  }
  try {
    if (Number(numberStr) === 0) {
      numberStr = Number(numberStr).toFixed(Math.abs(Number(digital ?? 0)));
    } else if (Number(numberStr) < 0.00001 && Number(numberStr) > 0) {
      const str1 = Number(numberStr).toFixed(Math.abs(Number(digital)) + 4);
      numberStr = str1.substr(0, Number(digital) + 2);
    } else if (Math.abs(Number(numberStr)) < 0.00001 && Number(numberStr) < 0) {
      console.info(numberStr);
      numberStr = `${numberStr}0000`;
      numberStr = numberStr.substr(0, Number(digital) + 3);
    } else {
      // numberStr = '0.' + (Number(numOrString).toString() + '00000000').substr(2, digital)
      const arr1 = Number(numberStr).toString().split(".");

      const splitPointStr = Number(digital) > 0 ? "." : "";
      if (arr1.length === 1) {
        arr1[1] = "00000000";
      } else {
        arr1[1] += "00000000";
      }
      numberStr = arr1[0] + splitPointStr + arr1[1].substr(0, digital);
    }
  } catch (e) {
    //
  }

  return numberStr;
};

/**
 * @description: 获取指定名称的cookie值
 * @params {
 * name: 需要获取的cookie的key
 * }
 * @return {string}
 */
export const getCookie = (name: string) => {
  // (^| )name=([^;]*)(;|$),match[0]为与整个正则表达式匹配的字符串，match[i]为正则表达式捕获数组相匹配的数组；
  let arr = new RegExp(`(^| )${name}=([^;]*)(;|$)`).exec(document.cookie);
  if (arr != null) {
    return decodeURI(arr[2]);
  }
  return null;
};

/**
 * @description: 处理数字，小数点后最多X位, 默认是8, 截取而不是四舍五入
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */
// @ts-ignore
export const decimalPointNoMoreX = (value, x = 8) => {
  if (isNaN(Number(value))) return value;
  if (typeof value === "number" || typeof value === "string") {
    let str = `${value}`;
    if (/e/i.test(str) && typeof value === "number") {
      value = value.toFixed(18).replace(/\.?0+$/, ""); //转换科学技术法
    }
    value = value.toString();
    const pos = value.indexOf(".");
    // 判断是否有小数点
    if (pos > -1) {
      if (x == 0) return value.split(".")[0];
      value.substring(pos + 1).length > x ? (value = value.substring(0, pos + x + 1)) : null;
      const valueDicimalLength = value.split(".")[1].length;
      if (valueDicimalLength < x) {
        value = value + "0".repeat(x - valueDicimalLength);
      }
    } else if (x > 0) {
      value = `${value}.${"0".repeat(x)}`;
    }
    return value;
  }
};

/**
 * @description: 处理数字，小数点后最多X位, 默认是8, 截取而不是四舍五入，注意！不会自动补充小数点后x位
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */
// @ts-ignore
export const decimalPointNoMoreXNoFill = (value, x = 8) => {
  if (typeof value === "number" || typeof value === "string") {
    let str = `${value}`;
    if (/e/i.test(str) && typeof value === "number") {
      value = value.toFixed(18).replace(/\.?0+$/, ""); //转换科学技术法
    }
    value = value.toString();
    const pos = value.indexOf(".");
    // 判断是否有小数点
    if (pos > -1) {
      value.substring(pos + 1).length > x ? (value = value.substring(0, pos + x + 1)) : null;
    }

    const reg = REG_EXP_NUMBER;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      return value;
    }
  }
};

/**
 * @description:数字向上取整
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */
export const decimalUpInteger = (value: number | string | undefined) => {
  let decimal = decimalPointNoMoreX(value, 8);
  let integer = decimalPointNoMoreX(value, 0);
  return Number(decimal) == Number(integer) ? Number(integer) : Number(integer) + 1;
};

/**
 * @description:
 * @params {
 * value：需要处理的数字（或者数字字符串）
 * }
 * @return {string}
 */
// @ts-ignore
export const formatHandicapNumber = (value, symbolList, record, precision) => {
  console.info(symbolList?.[`${record.baseCurrency}_${record.quoteCurrency}`]);
  return numberToThousands(
    decimalPointNoMoreX(
      value,
      symbolList?.[`${record.baseCurrency}_${record.quoteCurrency}`]?.[precision]
    )
  );
};

/**
 * 单独使用方法会有些问题，如果输入框仅支持数字和一个小数点，可以参考交易页面Plcace order的输入框， 后续会删除此方法
 * @description: only number is sopported and just one '.' at the end, '.' at the before is not allowed
 * @params e：需要处理的数字 或者字符串
 * @return {string}
 */
export const onlyNumbersAndDecimalPoint = (e: string | number) => {
  const RegxNumber = /^\d*(\.\d*)?$/;
  let currenValue = e.toString();

  if (
    isNaN(Number(currenValue)) ||
    !RegxNumber.test(currenValue) ||
    currenValue == "" ||
    currenValue == "-"
  ) {
    return currenValue.slice(0, -1);
  }
  // '.' at the end
  if (currenValue.endsWith(".")) {
    //currenValue.replace(/0*(\d+)/, '$1')
    //currenValue = currenValue.slice(0, -1);
  }
  return currenValue;
};

/**
 * @description: 计算离x最近的y的倍数
 * @params {
 * x:
 * y:
 * }
 * @return {number}
 */
export const nearestMultiple = (x: any, y: any) => {
  let val = x / y;
  let valStr = val.toString();
  const pos = valStr.indexOf(".");
  if (pos > -1) {
    valStr = valStr.substring(0, pos);
  }

  const result = parseFloat((Number(valStr) * y).toPrecision(12));
  return result;
};

/**
 * @description: 伪造唯一设备ID
 * @params 不需要入参数
 * @return string 唯一设备Id
 */
export const createUUID: () => string = () =>
  "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c: string): string {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/**
 * @description: 转化科学计数法
 * @params value 需要处理的数字/字符串
 * @return string 处理后的数值
 */
export const scientificToNumber: (value: number | string) => number | string = (value) => {
  if (isNaN(Number(value)) || !/e/i.test(value.toString())) {
    return value;
  }
  return Number(value)
    .toFixed(18)
    .replace(/\.?0+$/, "");
};

/**
 * 数字运算（主要用于小数点精度问题）
 * @param {number} a 前面的值
 * @param {"+"|"-"|"*"|"/"} type 计算方式
 * @param {number} b 后面的值
 * @example
 * ```js
 * // 可链式调用
 * const res = computeNumber(1.3, "-", 1.2).next("+", 1.5).next("*", 2.3).next("/", 0.2).result;
 * ```
 */
export const computeNumber = (a: number, type: string, b: number) => {
  /**
   * 获取数字小数点的长度
   * @param {number} n 数字
   */
  function getDecimalLength(n: number) {
    const decimal = n.toString().split(".")[1];
    return decimal ? decimal.length : 0;
  }

  /**
   * 修正小数点
   * @description 防止出现 `33.33333*100000 = 3333332.9999999995` && `33.33*10 = 333.29999999999995` 这类情况做的处理
   * @param {number} n
   */
  const amend = (n: number, precision = 15) => parseFloat(Number(n).toPrecision(precision));
  const power = Math.pow(10, Math.max(getDecimalLength(a), getDecimalLength(b)));
  let result = 0;

  a = amend(a * power);
  b = amend(b * power);

  switch (type) {
    case "+":
      result = (a + b) / power;
      break;
    case "-":
      result = (a - b) / power;
      break;
    case "*":
      result = (a * b) / (power * power);
      break;
    case "/":
      result = a / b;
      break;
  }

  result = amend(result);

  return {
    /** 计算结果 */
    result,
    /**
     * 继续计算
     * @param {"+"|"-"|"*"|"/"} nextType 继续计算方式
     * @param {number} nextValue 继续计算的值
     */
    next(nextType: string, nextValue: number) {
      return computeNumber(result, nextType, nextValue);
    }
  };
};

/**
 * @description: 获取昨天，前天，明天，后天，类似的日期
 * @params offset: +N，-N, N默认为+, 天
 * @return string 日期字符串
 */
export const getSpecifiedDate = (offset: number) => {
  const today = new Date();
  let offseTime = 0;
  let flag = "+";
  let _offset = offset.toString();
  if (_offset.includes("+")) {
    _offset = _offset.split("+")[1];
  }
  if (_offset.includes("-")) {
    _offset = _offset.split("-")[1];
    flag = "-";
  }
  offseTime = Number(_offset) * 24 * 60 * 60 * 1000;
  if (flag == "-") {
    today.setTime(today.getTime() - offseTime);
  } else {
    today.setTime(today.getTime() + offseTime);
  }
  return transformTime(today.getTime());
};

export const convertUSD2USDC: (symbol: string) => string = (symbol) => {
  let str = symbol;
  if (str?.toLowerCase()?.includes("usd") && str?.endsWith("USD")) {
    str = str.replace(/(USD)$/, BENCHMARK_CURRENCY);
  }
  if (str?.toLowerCase()?.startsWith("usd/")) {
    str = str.replace(/(USD\/)/, `${BENCHMARK_CURRENCY}/`);
  }
  if (str?.toLowerCase()?.startsWith("usd_")) {
    str = str.replace(/(USD_)/, `${BENCHMARK_CURRENCY}_`);
  }
  return str;
};

// usd后续合并通用constant常量时替换
export const convertUSDC2USD: (symbol: string) => string = (symbol) => {
  let str = symbol;
  if (str?.toLowerCase()?.includes("usdc") && str?.toLowerCase()?.endsWith("usdc")) {
    str = str.replace(/(USDC)$/, REFERENCE_CURRENCY);
  }
  if (str?.toLowerCase()?.startsWith("usdc/")) {
    str = str.replace(/(USDC\/)/, `${REFERENCE_CURRENCY}/`);
  }
  if (str?.toLowerCase()?.startsWith("usdc_")) {
    str = str.replace(/(USDC_)/, `${REFERENCE_CURRENCY}_`);
  }
  return str;
};

/**
 * @description: 重构比例数据格式
 * @params {BTC:1,ETH:1,XRP:1}
 * @return []
 */
export const resetRate = (params: { [key: string]: number }) => {
  if (!params) {
    return;
  }
  const planCoinList: string[] = Object.keys(params);
  const rateValueList: number[] = Object.values(params);
  let sum = 0;
  rateValueList.map((item: number) => {
    sum = sum + item;
  });
  let rateList: rateModel[] = [];
  let percent = 10000; //百分比后两位小数
  for (let i = 0; i < planCoinList.length; i++) {
    const percentItem = Number(Math.floor((rateValueList[i] / sum) * 10000));
    if (i === planCoinList.length - 1) {
      rateList.push({
        denomination: planCoinList[i],
        rate: percent / 10000,
        percent: `${(percent / 100).toFixed(2)}%`
      });
    } else {
      percent = percent - percentItem;
      rateList.push({
        denomination: planCoinList[i],
        rate: percentItem / 10000,
        percent: `${(percentItem / 100).toFixed(2)}%`
      });
    }
  }
  return rateList;
};

/**
 * @description: 新版重构比例数据格式
 * @params {BTC:1,ETH:1,XRP:1}
 * @return IAutoInvestCoinItem[]
 */
export const resetRateNew = (
  params: Map<string, number> | { [p: string]: number },
  contractType = 1
): IAutoInvestCoinItem[] => {
  const planCoinList: string[] = Object.keys(params);
  const rateValueList: number[] = Object.values(params);
  const decimal = contractType === 0 ? 2 : undefined;
  let sum = 0;
  rateValueList.map((item) => {
    sum = sum + item;
  });
  let rateList: IAutoInvestCoinItem[] = [];
  let percent = 10000; //百分比后两位小数
  for (let i = 0; i < planCoinList.length; i++) {
    const percentItem = Number(Math.floor((rateValueList[i] / sum) * 10000));
    if (i === planCoinList.length - 1) {
      rateList.push({
        currency: planCoinList[i],
        percent: (percent / 100).toFixed(decimal),
        error: "",
        select: true
      });
    } else {
      percent = percent - percentItem;
      rateList.push({
        currency: planCoinList[i],
        percent: (percentItem / 100).toFixed(decimal),
        error: "",
        select: true
      });
    }
  }
  return rateList;
};

/**
 * @description: 判断浏览器是否支持webp格式的图片
 * @params callback 返回结果
 * @return Boolean
 */
// @ts-ignore
export const checkSupportWebp = (feature, callback) => {
  const kTestImages = {
    // lossy:“有损”,lossless:“无损”,alpha:“α”或animation:“动画”。
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
  };

  const img = new Image();
  img.onload = function () {
    const result = Boolean(img.width > 0 && img.height > 0);
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
  // @ts-ignore
  img.src = `data:image/webp;base64,${kTestImages[feature]}`;
};

/**
 * 根据输入的值获取结果展示文案颜色，up|down|remain
 */
export const getCurrentColor: (value: number | string) => "up" | "down" | "remain" = (value) => {
  if (Number(value) > 0) {
    return "up";
  } else if (Number(value) < 0) {
    return "down";
  }
  return "remain";
};

/**
 * @description: 根据国家code查询kyc国家信息
 * @return Object {countryCode:ICountriesData}
 */
export const countryCode2Info: () => { [key: string]: ICountriesData } = () => {
  let obj: { [key: string]: ICountriesData } = {};
  userCountriesData.map((item) => {
    obj[item.id] = item;
  });
  return obj;
};

export const getCurrentPathName: (path: string) => string = (path) => {
  let pathname = path;
  if (pathname?.includes("/[")) {
    pathname = pathname.split("/[")[0];
  }
  return pathname;
};

export const getPathNameWithoutLang: (path: string) => string = (path) => {
  let pathname = path.replace("/zh", "").replace("/", "");
  if (pathname == "/" || pathname == "") pathname = "home";
  return pathname;
};

/**
 * 注册登陆重置密码时，input focus和blur公用方法
 * @param event
 */
export const handleBlur: (event: { target: { value: any }; currentTarget: any }) => void = (
  event
) => {
  if (!event.target.value) {
    event.currentTarget.parentNode.firstElementChild.style.zIndex = 0;
    event.currentTarget.parentNode.firstElementChild.style.top = "0";
    event.currentTarget.parentNode.firstElementChild.style.color = "#ffffffb3";
  }
};

export const handleFocus: (event: { target: { value: any }; currentTarget: any }) => void = (
  event
) => {
  event.currentTarget.parentNode.firstElementChild.style.top = "-15px";
  event.currentTarget.parentNode.firstElementChild.style.zIndex = 1;
  event.currentTarget.parentNode.firstElementChild.style.color = "#666666";
};

/**
 * @description: 根据env获取系列环境变量
 * @param {IEnvs}
 * @return {IEnvConfig}
 */
export const getEnvConfig: (env: IEnvs) => IEnvConfig = (env) => {
  const configs = getConfigs();
  let config = configs.dev;
  switch (env) {
    case "dev":
      config = configs.dev;
      break;
    case "beta":
      config = configs.beta;
      break;
    case "pre":
      config = configs.pre;
      break;
    case "prod":
      config = configs.prod;
      break;
    default:
      config = configs.dev;
      break;
  }
  return config;
};

/**
 * @description: 处理路由名称过程问题
 * @param { pathName } string // 当前要处理的路由
 * @return { routePath: string; pathname: string;  param: Record<string, any> }
 *  routerPath 当前path的路由path，主要是区分是否有上一个页面
 */
// TODO, pathname 名称过长/不合理跟踪处理  @孟花
export const handleEventPathname: (pathName: string) => {
  routePath: string;
  pathname: string;
  param: Record<string, string | number | boolean>;
} = (pathName) => {
  let _toPathName = pathName;
  let _routePath = pathName;
  const _detailParams: Record<string, string | number | boolean> = {};

  const tradeReg = /(trade)\/\w+$/;
  /*
      intelligence/briefing/detail/sdsdds => {"briefing/detail", "sdsdds"}
      intelligence/new/noticeDetail/ddfdf => {"new/noticeDetail", "ddfdf"}
    */
  const detailReg = /\w+\/[a-zA-z]*detail\//i;
  if (detailReg.test(_toPathName)) {
    const urls = _toPathName.split(detailReg);
    // @ts-ignore
    const results: RegExpMatchArray = detailReg.exec(_toPathName);
    let matchedPath = results[0];
    _toPathName = matchedPath.substring(0, matchedPath.length - 1);
    _routePath = _toPathName;
    const detailId = urls.length > 1 ? urls[1] : "";
    const _id = detailId?.split("?")?.[0];
    _detailParams.id = _id;
    // 如果id会自动转成科学计数法，则手动拼接"id="的前缀
    if (!isNaN(Number(_id)) && Number(_id).toString().includes("e")) {
      _detailParams.id = `id=${_id}`;
    }
  } else if (tradeReg.test(_toPathName) && !_toPathName.includes("_To_")) {
    // @ts-ignore
    const results: RegExpMatchArray = tradeReg.exec(_toPathName);
    _toPathName = results.length > 1 ? results[1] : _toPathName;
  } else if (_toPathName.includes(INTELLIGENCE_PATHS.INTELLIGENCE)) {
    /* 资讯不包含 detail的场景也要处理 /briefing_To_intelligence/news => briefing_To_news
        intelligence/xxx => [xxx]
        intelligence/level1/level2 => [level1/level2]
        */
    const urls = _toPathName.split("intelligence/");
    _toPathName = urls.length > 1 ? urls[1] : _toPathName; //
  }
  if (!pathName.includes(INTELLIGENCE_PATHS.INTELLIGENCE) && _toPathName.includes("?")) {
    const urls = _toPathName.split("?");
    _toPathName = urls[0];
    _routePath = _toPathName;
    const _id = urls[1]?.toString();
    _detailParams.id = _id;
    // 如果id会自动转成科学计数法，则手动拼接"id="的前缀
    if (!isNaN(Number(_id)) && Number(_id).toString().includes("e")) {
      _detailParams.id = `id=${_id}`;
    }
  }
  return {
    routePath: _routePath,
    pathname: _toPathName,
    param: _detailParams
  };
};

/**
 * @description: 在使用JSON.stringify方法去转化成字符串，会报错TypeError: Converting circular structure to JSON, 原因：对象中有对自身的循环引用；
 * @param { obj } JSON
 * @return { string }
 */
export const convertJsonToString: (obj: object) => string = (obj) => {
  let cache: any[] = [];
  let json_str = JSON.stringify(
    obj,
    function (_, value) {
      if (typeof value === "object" && value !== null) {
        // @ts-ignore
        if (cache.indexOf(value) !== -1) {
          return;
        }
        // @ts-ignore
        cache.push(value);
      }
      return value;
    },
    "\t"
  );
  return json_str;
};

/**
 * @description: 处理语言切换
 * @param language 当前语言 ILanguageType
 * @return
 */
export const handleSwitchLanguage: (lang: ILanguageType) => void = (lang) => {
  // switchLanguage(),  TODO
  localStorage.setItem(LANGUAGE, lang);
  reportEvent({
    moduleName: GA_EVENT_NAME.common.switchLanguage,
    detailParams: { language: lang }
  });
};

export const getSessionStorageTokenKey = (prefix: string, type?: string) => {
  switch (type) {
    case "agent":
      return `${prefix}${AGENT_OPERATING_TOKEN}`;
    default:
      return `${prefix}${JWT_TOKEN}`;
  }
};

/**
 *
 * @description: 判断绘图数据是否有效
 * @param {data} 绘图数据
 * @param {xField} 横坐标key
 * @param {yField} 纵坐标key
 * @return {IChartDataValidate}
 */
export const validateChartData = function <T>(
  data: T[],
  xField: string,
  yField: string
): IChartDataValidate<T> {
  if (!Array.isArray(data) || !data.length || xField === "" || yField === "")
    return {
      validate: false,
      data
    };
  let _newData: T[] = [];
  data.map((item: T) => {
    if (
      item &&
      Object.prototype.hasOwnProperty.call(item, xField) &&
      Object.prototype.hasOwnProperty.call(item, yField)
    ) {
      _newData.push(item);
    }
  });
  const isValidate = _newData.length === data.length;
  if (!isValidate) {
    // 存在异常数据点
    Bugsnag.notify(new Error(formatBugsnagMessage("Assets Chart has error point")));
  }
  return {
    validate: isValidate,
    data: _newData
  };
};

/**
 * 取余(模)运算
 * @params dividend：被除数， divisor：除数
 * @return {boolean}
 */
export const isComplementation = (
  dividend: number | string | undefined | null,
  divisor: number | string | undefined | null
) => {
  if (dividend && divisor) {
    return (
      parseFloat((Number(dividend) * 100000000).toPrecision(12)) %
        parseFloat((Number(divisor) * 100000000).toPrecision(12)) ===
      0
    );
  }
  return false;
};

/**
 * @description: 获取两个数之间的随机数
 * @param min 最小值
 * @param max 最大值
 * @return number
 */
export const getRandomNumber: (min: number, max: number) => number = (min, max) => {
  return Math.random() * (max - min) + min;
};

/**
 * @description: 生成key
 * @param param 生成key的参数，eg: type=0&offset=0&limit=30
 * 约定： 相同接口，相同参数，那么不管在任何页面，初始数据是可以共用的。
 * customerId 一般不需要传递，帮客户时注意测试，也可以自行传递
 * @return string
 */
export const generateDataCacheKey: (param: IGenerateDataCacheKey) => string = ({
  params,
  api,
  language,
  customerId
}) => {
  const _language = language;
  // OPERATE_CUSTOMER_ID 保存的帮客或代客的客户Id，如果是帮客或代客 需要查询的是被操作客户缓存的值
  const _customerId = customerId
    ? customerId
    : isClient &&
      (localStorage.getItem(OPERATE_CUSTOMER_ID) || sessionStorage.getItem(CUSTOMER_ID));
  const isKXFKSDKDS = isClient && (process?.env?.NEXT_PUBLIC_REFSFGADEF ?? "");
  const _env =
    isClient && (process.env.NODE_ENV != "production" || isKXFKSDKDS ? host.dev : host.prod);
  if (_language) {
    return `${_env}_cache_${_language}_${_customerId}_${api}_${params}`;
  }
  return `${_env}_cache_${_customerId}_${api}_${params}`;
};

/**
 * @description: 用来展示双币不同产品类型的目标价
 * @param value 展示金额
 * @param quote 币种类型
 * @param precisions 精度
 * @return string
 */
export const showStrike: (value: number, quote: string, precisions?: number) => string = (
  value,
  quote,
  precisions
) => {
  return quote
    ? `${numberToThousands(decimalPointNoMoreX(value, precisions ?? 8))} ${convertUSD2USDC(quote)}`
    : `${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(decimalPointNoMoreX(value, 2))}`;
};

/**
 * @description: 获取当前使用的语言
 * @returns {ILanguageType}
 */
export const getCurrentLanguage: () => ILanguageType = () => {
  return (isClient && window.localStorage.getItem(LANGUAGE)) as ILanguageType;
};

/**
 * @description: 手机号隐藏
 * @returns string
 */
export const hiddenPhoneText: (phone: string) => string = (phone: string) => {
  let text = phone;
  if (!phone) {
    return text;
  }
  return `${phone.substring(0, 3)}****${phone.substring(7, phone.length)}`;
};

// 参考：https://developers.heap.io/reference#adduserproperties
export const addHeapIdentify = (unique_identifier: string | number, account: string) => {
  if (isClient && window.heap) {
    // todo，放开
    // window.heap.identify(unique_identifier);
    //   window.heap.addUserProperties({ account: account });
  }
};

export const filterSymbolsList: (value: string, allData: string[]) => string[] = (
  value,
  allData
) => {
  return (
    allData?.filter((e) => {
      const baseCoin = e.split("_")[1];
      return baseCoin === value;
    }) || []
  );
};

export const formatNumToKMB = (num: any) => {
  if (!num) {
    return num;
  }
  num = num || 0;
  const arr = num.toString().split(".");
  num = arr[0];
  let end = arr[1] ? `.${arr[1]}` : "";
  let result = "";
  const symbol = num.startsWith("-") ? "-" : "";
  if (num.startsWith("-")) {
    num = num.replace("-", "");
  }
  if (Number(num) < 1000) {
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
  } else if (Number(num) < 1000000) {
    result = (Number(num) / 1000).toFixed(2);
    end = "K";
  } else if (Number(num) < 1000000000) {
    result = (Number(num) / 1000000).toFixed(2);
    end = "M";
  } else {
    result = (Number(num) / 1000000000).toFixed(2);
    end = "B";
  }

  return symbol + result + end;
};
export const getComplianceKey = (routeName: string) => {
  const customerId = localStorage.getItem("customerId");
  const key = `${KEY_COMPLIANCE_TAG}-${routeName}-${customerId}`;
  return key;
};
export const getComplianceTag = (routeName: string) => {
  return localStorage.getItem(getComplianceKey(routeName));
};
export const saveComplianceTag = (routeName: string) => {
  return localStorage.setItem(getComplianceKey(routeName), "true");
};

/**
 *
 * @param res BlobPart暂时接受Blob
 * @param type 媒体类型
 * text/plain：纯文本类型
 * text/html：HTML 文档类型
 * application/json：JSON 数据类型
 * image/jpeg：JPEG 图像类型
 * image/png：PNG 图像类型
 * audio/mp3：MP3 音频类型
 * video/mp4：MP4 视频类型
 * application/pdf：PDF 文档类型
 * @param fileName 文件名+类型后缀
 */
export const downLoadFileBlob = (res: BlobPart, type = "application/pdf", fileName?: string) => {
  const responseBuffer = res;
  const blob = new Blob([responseBuffer], { type });
  console.log("downLoadFileBlob", type);
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  // 设置下载文件的名称
  link.download = fileName || "file.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // 清理 Blob URL
  URL.revokeObjectURL(link.href);
};

export const getBuyCoinText = (coinCode: string): string => {
  let arr = coinCode.split("_");
  return `${convertUSD2USDC(arr[1])} —> ${convertUSD2USDC(arr[0])}`;
};
export const getSellCoinText = (coinCode: string) => {
  let arr = coinCode.split("_");
  return `${convertUSD2USDC(arr[0])} —> ${convertUSD2USDC(arr[1])}`;
};

export const isDev = process.env.NODE_ENV === "development";

export const isClient = typeof window !== "undefined";

/**
 * @description 处理路由跳转时的ga上报
 * @param {string} url
 */
export const handleRouteChangeStart = (url: string) => {
  // 页面跳转之前的路径 /home
  let _currenRoutePath = getPathNameWithoutLang(location.pathname);
  // 页面要跳转的路径 /ventures/detail/239023679236823
  let _toRoutePath = getPathNameWithoutLang(url);
  let _path = _currenRoutePath;
  let _detailParams = {};
  // 处理当前路由
  const _handledCurrentPath = handleEventPathname(_currenRoutePath);
  _currenRoutePath = _handledCurrentPath.routePath;
  if (_handledCurrentPath.param.param_id) {
    _detailParams = { ..._detailParams, id: _handledCurrentPath.param.param_id };
  }
  // 处理跳转路由
  const _handledToPath = handleEventPathname(_toRoutePath);
  _toRoutePath = _handledToPath.routePath;
  _detailParams = { ..._detailParams, ..._handledToPath.param };
  // 路由变化时上报GA  由于 pathname 做了一些特殊处理，导致这个判断满足，所以此处引入 routerPath 来进行比较
  if (_currenRoutePath !== _toRoutePath) {
    _path = `${_handledCurrentPath.pathname}_To_${_handledToPath.pathname}`;
    reportEvent({ pathname: _path, detailParams: _detailParams });
  } else {
    // 上报当前页面访问量
    const _current = _handledToPath.pathname;
    reportEvent({ pathname: _current, detailParams: _detailParams });
  }
};
export const errorSize = 10;

export const uploadNetWorkError = (err: any) => {
  let netErrorList: string[] = [];
  let message = formatBugsnagMessage(convertJsonToString(err));
  //简单的网络错误不要重复上传
  if (message.includes("Network Error") || message.includes("timeout of")) {
    const re = /\[XHR\]--path: .+?(?=--Error)/gims;
    const simpleError = message.match(re)?.[0];
    //将简化版网络错误放入栈中
    simpleError && netErrorList.push(simpleError);
    if (netErrorList.length >= errorSize) {
      //上传错误
      //错误栈满了,重置错误栈
      Bugsnag.notify(new Error(netErrorList.join(" ; \n")));
      netErrorList = [];
    }
    return;
  }
  Bugsnag.notify(new Error(message));
};

export const getFormatWebp = () => {
  const formatwebp = isClient && (localStorage.getItem(FORMATWEBP) || "");
  return formatwebp ? `?x-oss-process=image/${formatwebp}` : "";
};

// 校验输入框输入数字位数，num不传，默认可以输入8位，num只能传大于0的整数
// 返回
/**
 * @description 校验输入框输入数字位数，num不传，默认可以输入8位，num只能传大于0的整数
 * @params inputValue: 输入值, num?: 需要保留的小数位
 * @return false | string，校验不通过，返回false，校验通过，返回截取小数位之后的字符串
 */
export const validAmount = (inputValue: string, num?: number): false | string => {
  try {
    // 只能输入正数
    if (!REG_EXP_POSITIVE_NUMBER.test(inputValue)) {
      return false;
    }
    // 输入框限制最多允许输入八位小数
    let params = inputValue.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".");
    const count = num ?? 8;
    const reg = new RegExp(`^(-)*(\\d+)\\.(\\d{1,${count}}).*$`);
    const value = params.replace(reg, "$1$2.$3");
    if (isNaN(Number(value))) {
      return false;
    }
    return value;
  } catch (e) {
    return false;
  }
};
export const md5Encrypted = (target: string, secretKey?: string) => {
  if (secretKey) {
    return md5(target + secretKey);
  }
  return md5(target);
};

export const getDefaultCoinCodeForTrade = (system: TSystem, queryCoinCode?: string) => {
  const defaultCoinCode = system === BRAND ? BTC_USDT : BTC_USDC;
  const showCoinCode = queryCoinCode ?? defaultCoinCode;
  const coinCode = system === BRAND ? convertUSDC2USD(showCoinCode) : showCoinCode;
  return coinCode;
};

// 该方法适用于strategy venture fund详情，用于判断产品当前状态展示情况
export const judgeProductStatus: (startTime: number, endTime: number) => string = (
  startTime,
  endTime
) => {
  const now = new Date().getTime();
  if (Number(now) > endTime) {
    return "close";
  }
  if (Number(now) < startTime) {
    return "comingSoon";
  }
  return "ongoing";
};

/**
 * 该方法用于在不同系统下,统一返回所需币种参数的名称。
 * 主要区分USD,USDC二者（原Aspen所需 "USD" 的情况，在ADGM中需要改为USDC）
 * @param system 当前系统
 */
export const getReferenceCurrencyBySystem = (system: TSystem) => {
  return system === BRAND ? REFERENCE_CURRENCY : BENCHMARK_CURRENCY;
};
