import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialState {
  saasInterestVentures: string[];
}

const initialState: InitialState = {
  saasInterestVentures: []
};

export const SaasVenturesSlice = createSlice({
  name: "Ventures",
  initialState,
  reducers: {
    updateSaasInterestVentures: (state, action: PayloadAction<string[]>) => {
      state.saasInterestVentures = action.payload;
    },
    clearSaasAllVentures: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    }
  }
});

export const saasInterestVentures = (state: RootState) => state.saasInterestVentures;

export const { updateSaasInterestVentures, clearSaasAllVentures } = SaasVenturesSlice.actions;

export default SaasVenturesSlice.reducer;
