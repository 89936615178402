import React, { memo } from "react";
import { Row } from "antd";
import styles from "@aspen/theme/Vip.module.less";
import { i18nUtil } from "@aspen/libs";
import Image from "next/image";
import SilverIcon from "@aspen/assets/images/vip/silver.png";
import GoldIcon from "@aspen/assets/images/vip/gold.png";
import BlackIcon from "@aspen/assets/images/vip/black.png";
import SilverTxt from "@aspen/assets/images/vip/silver_txt.png";
import GoldTxt from "@aspen/assets/images/vip/gold_txt.png";
import BlackTxt from "@aspen/assets/images/vip/black_txt.png";
import Checked from "@aspen/assets/images/vip/checked.png";
import { EVipType } from "@aspen/model";

interface PrivilegesTableProps {
  currentLevel: string;
}
const white = "#FFFFFF";
const greyDefault = "rgba(255,255,255,0.4)";
function PrivilegesTableView(props: PrivilegesTableProps) {
  const intl = i18nUtil.t();
  const title = intl["vip.servicesTitle"].slice();
  const topTitle = intl["vip.topTitle"];
  const silver_privileges = intl["vip.silver.privileges"].slice();
  const gold_privileges = intl["vip.gold.privileges"].slice();
  const black_privileges = intl["vip.black.privileges"].slice();

  const _renderListItem = (item: string, index: number) => {
    return (
      <li
        className={styles.txt}
        key={index}
        style={{
          borderRight: "none",
          justifyContent: "center",
          color: index === 2 || index === 3 ? greyDefault : white
        }}>
        {item === "checked" ? (
          <Image unoptimized key={index} width="16" height="12" src={Checked} alt="" />
        ) : (
          item
        )}
      </li>
    );
  };

  const handleBorderStyl = (tableLevel: string) => {
    if (tableLevel === props.currentLevel) {
      return true;
    }
    return false;
  };
  return (
    <div className={styles.privilegesTable}>
      {props?.currentLevel ? (
        <div className={styles.contentWrapper}>
          <ul className={`${styles.privilegesList} ${styles.servicesTitle}`}>
            <div className={styles.columns}>
              <p className={styles.txt} style={{ marginBottom: 33 }}>
                {topTitle[0]}
              </p>
              <p className={styles.txt}>{topTitle[1]}</p>
              <p className={styles.txt}>{topTitle[2]}</p>
            </div>
            {title.map((item, index) => {
              return (
                <li
                  className={styles.txt}
                  key={index}
                  style={{
                    color: index === 2 || index === 3 ? greyDefault : white
                  }}>
                  {item}
                </li>
              );
            })}
          </ul>
          <ul
            className={`${styles.privilegesList} ${
              handleBorderStyl(EVipType.Silver) ? styles.privilegesListBorder : ""
            }`}>
            <div className={styles.columns}>
              <Row style={{ alignItems: "center" }}>
                <Image
                  unoptimized
                  width="30"
                  height="30"
                  className={styles.img}
                  src={SilverIcon}
                  alt=""
                />
                <div className={styles.txtImgWrapper}>
                  <Image
                    unoptimized
                    width="60"
                    height="24"
                    className={styles.txtImg}
                    src={SilverTxt}
                    alt=""
                  />
                </div>
              </Row>
              <p className={styles.priceTxt}>{intl["constant.vip.sliver.amount"]}</p>
            </div>
            {silver_privileges.map((item, index) => {
              return _renderListItem(item, index);
            })}
          </ul>
          <ul
            className={`${styles.privilegesList} ${
              handleBorderStyl(EVipType.Gold) ? styles.privilegesListBorder : ""
            }`}>
            <div className={styles.columns}>
              <Row style={{ alignItems: "center" }}>
                <Image
                  unoptimized
                  width="30"
                  height="30"
                  className={styles.img}
                  src={GoldIcon}
                  alt=""
                />
                <div className={styles.txtImgWrapper}>
                  <Image
                    unoptimized
                    width="46"
                    height="22"
                    className={styles.txtImg}
                    src={GoldTxt}
                    alt=""
                  />
                </div>
              </Row>
              <p className={styles.priceTxt}>{intl["constant.vip.gold.amount"]}</p>
            </div>
            {gold_privileges.map((item, index) => {
              return _renderListItem(item, index);
            })}
          </ul>
          <ul
            className={`${styles.privilegesList} ${
              handleBorderStyl(EVipType.Black)
                ? styles.privilegesListBorder
                : styles.privilegesListNoBorder
            }`}>
            <div className={styles.columns} style={{ borderRight: "none" }}>
              <Row style={{ alignItems: "center" }}>
                <Image
                  unoptimized
                  width="30"
                  height="30"
                  className={styles.img}
                  src={BlackIcon}
                  alt=""
                />
                <div className={styles.txtImgWrapper}>
                  <Image
                    unoptimized
                    width="57"
                    height="24"
                    className={styles.txtImg}
                    src={BlackTxt}
                    alt=""
                  />
                </div>
              </Row>
              <p className={styles.priceTxt}>{intl["constant.vip.black.amount"]}</p>
            </div>
            {black_privileges.map((item, index) => {
              return _renderListItem(item, index);
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
const areEqual = (prevProps: PrivilegesTableProps, nextProps: PrivilegesTableProps) => {
  return prevProps.currentLevel === nextProps.currentLevel;
};
export const PrivilegesTable = memo(PrivilegesTableView, areEqual);
