import qs from "qs";
import axios from "axios";
import { CAN_REQUEST_AFTER_LOGIN_APIS } from "@aspen/libs/index";

// 把当前请求信息添加到pendingRequest对象中
const pendingRequest = new Map();
const pendingRetryRequest = new Map();

// 根据当前请求信息，生成请求key
function generateReqKey(config, extra?) {
  if (!config.method && !config.params && !config.data) return "";
  const { method, url, params, data } = config;
  if (data) {
    let _data = data;
    if (Object.prototype.toString.call(data) == "[object String]") {
      _data = qs.parse(data);
    }
    _data.method = method ?? "";
    _data.url = url;
    return qs.stringify(_data);
  }
  if (params) {
    params.method = method ?? "";
    params.url = url;
    return qs.stringify(params);
  }
  if (extra) return qs.stringify({ method, url, extra });
  return qs.stringify({ method, url });
}
function addPendingRequest(config) {
  const requestKey = generateReqKey(config);
  const pendingRequestKey = generateReqKey(config, "pending");
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
        pendingRetryRequest.set(pendingRequestKey, qs.stringify(config));
      }
    });
}

// 移除请求
function removePendingRequest() {
  pendingRequest.forEach((cancelToken, key) => {
    if (!CAN_REQUEST_AFTER_LOGIN_APIS.some((item) => key.includes(item))) {
      const _config = qs.parse(key);
      const requestKey = generateReqKey(_config);
      const cancelToken = pendingRequest.get(requestKey);
      if (cancelToken) {
        cancelToken(requestKey);
        pendingRequest.delete(key);
      }
    }
  });
}

export { addPendingRequest, removePendingRequest };
