import React from "react";
import styles from "@aspen/theme/Event.module.less";
import type { ConferenceVO, IEventStatus } from "@aspen/model";
import Image from "next/image";
import { CountdownView } from "./CountdownView";
import bannerBg from "@aspen/assets/images/event/bg_image.webp";
import SkeletonImage from "antd/lib/skeleton/Image";
import variables from "@aspen/theme/variables.module.less";

interface IProps {
  data: ConferenceVO;
  status: IEventStatus | undefined;
}

const EventBannerView: React.FC<IProps> = (props) => {
  const now = new Date().getTime();
  const { data, status } = props;
  return (
    <div className={styles.appBanner}>
      {data?.image ? (
        <Image unoptimized src={data?.image || ""} alt="" fill objectFit="cover" />
      ) : (
        <SkeletonImage style={{ width: 375, height: 200, background: variables.moduleBg }} />
      )}
      {status === "coming" ? (
        <div className={styles.absoluteFull}>
          <Image unoptimized src={bannerBg} alt="" fill />
          <div className={styles.container}>
            <div className={styles.timerContainer}>
              <CountdownView startTime={now} endTime={data.startTime || 0} size="normal" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export const AppEventBanner = React.memo(EventBannerView);
