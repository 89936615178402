// 在这里TradeSlice里存放实时交易币币种信息，供需要的页面去取值做计算，
import exchangeReducer from "./TradeSlice";
import userReducer from "./UserSlice";
import marketCurrenciesReducer from "./MarketCurrenciesSlice";
import walletReducer from "./WalletSlice";
import yieldReducer from "./YieldSlice";
import { combineReducers } from "redux";

const reducers = combineReducers({
  marketSpotAndTradeInfo: exchangeReducer,
  user: userReducer,
  currencies: marketCurrenciesReducer,
  wallet: walletReducer,
  yield: yieldReducer
});

export default reducers;

export * from "./MarketCurrenciesSlice";
export * from "./TradeSlice";
export * from "./UserSlice";
export * from "./WalletSlice";
export * from "./YieldSlice";
