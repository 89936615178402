import type { ICacheItemConfig, ICommonCacheKeys } from "@aspen/model";
import { AIPProductType } from "@aspen/model";
import { aspenApi as api } from "./api";
import { EN, ZH_CN } from "@aspen/libs";

/**
 * @description: 需要缓存数据的接口，有新的需要缓存数据的接口，,需要在这里配置key(key名尽量和reux key保持一致)
 *  api 接口地址
 *  param 接口参数，不需要传参数时，将参数设置为undefined
 *  language 接口语言, 不区分语言时，设置为 "", 或者不设置
 */
export const DATA_CACHES_KEYS: Record<ICommonCacheKeys, ICacheItemConfig<any>> = {
  autoInvestList: {
    api: api.savings.autoInvest.products,
    param: {
      limit: 10,
      type: AIPProductType.Normal
    }
  },
  // staking产品
  stakingProducts: {
    api: api.savings.staking.productList,
    param: { limit: 10 },
    cacheResDataKey: "rows" // 需要缓存的接口返回数据特定的key，默认是res.data, 部分接口是res.data.rows等
  },
  // 钱包数据
  walletInfo: {
    api: api.wallet.walletInfo, //"wallet/walletInfo",
    param: { showRevenue: true },
    language: "",
    options: {
      onError: (err, key, config) => {
        // Handle error
      }
    }
  },
  strategies: {
    api: api.strategies.strategyList,
    param: { limit: 100 },
    language: [ZH_CN, EN]
  },
  funds: {
    api: api.fund.fundList,
    param: { limit: 100 },
    language: [ZH_CN, EN]
  },
  // 首次需要的资产打点数据
  assetsLineData: {
    api: api.assets.static, //"asset/statics",
    param: {
      type: 0, //day 0, week 1, month 2, year 3 目前默认只显示0
      offset: 0,
      limit: 30
    },
    language: ""
  },
  depositCoinList: {
    api: api.wallet.assetCurrencyConfig,
    param: undefined,
    language: ""
  },
  spotAccounts: {
    api: api.wallet.spot,
    param: undefined,
    language: ""
  },
  primaryVentureList: {
    api: api.ventures.ventureList,
    param: {
      limit: 100,
      shares: 0
    },
    language: [ZH_CN, EN]
  },
  secondaryVentureList: {
    api: api.ventures.ventureList,
    param: {
      limit: 100,
      shares: 1
    },
    language: [ZH_CN, EN]
  },
  ventureDetail: {
    api: api.ventures.ventureDetail,
    param: {
      ventureInfoId: "customer"
    },
    language: [ZH_CN, EN]
  },
  strategiesDetail: {
    api: api.strategies.strategyDetail,
    param: {
      strategyId: "customer"
    },
    language: [ZH_CN, EN]
  },
  fundsDetail: {
    api: api.fund.fundDetail,
    param: {
      fundId: "customer"
    },
    language: [ZH_CN, EN]
  },
  yieldFlexibleProducts: {
    api: api.savings.flexible.products,
    param: { limit: 100 },
    language: [ZH_CN, EN]
  },
  yieldFixedProducts: {
    api: api.savings.fixed.productList,
    param: { limit: 10 },
    language: [ZH_CN, EN]
  },
  eventsList: {
    api: api.events.list,
    param: { limit: 100, type: "" }
  },
  eventsDetail: {
    api: api.events.detail,
    param: undefined
  },
  venturePositions: {
    api: api.ventures.venturePosition,
    param: undefined
  }
};

// 缓存传递的key值
export type ICachekeys = keyof typeof DATA_CACHES_KEYS | undefined;
