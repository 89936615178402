import type { ReactChild } from "react";
import React from "react";
import type { ModalProps } from "antd";
import { Modal } from "antd";
import styles from "./index.module.less";

export interface ModalBaseProps extends ModalProps {
  buttonType?: "default" | "only";
  children?: ReactChild;
}

export const ModalBase: React.FC<ModalBaseProps> = (props) => {
  const { buttonType = "default", className, width = 480, children, ...restProps } = props;

  let modalClassName = "baseModal";

  if (buttonType === "only") {
    modalClassName += " only";
  }

  if (className) {
    modalClassName += ` ${className}`;
  }

  return (
    <div className={styles.defaultModal}>
      <Modal className={modalClassName} centered width={width} destroyOnClose {...restProps}>
        {children ? children : null}
      </Modal>
    </div>
  );
};
