export const SAAS_HEADER_TITLE = "Tenant";
export const SAAS_PARAMS_DOMAIN_NAME = "saasDomainName";

// 白标客户端jwtToken
export const SAAS_TOKEN = "saasJwtToken";
export const SAAS_ACCOUNT = "saasLoginAccount";
export const SAAS_USERID = "saasUserId";
export const SAAS_IS_LOGIN = "saasIsLogin";
export const SAAS_LOGIN_REDIRECT = "saasLoginRedirect";
export const SAAS_REDIRECT_ID = "saasRedirectId";
// 白标客户端租户id
export const SAAS_CURRENT_DOMAIN = "saasCurrentDomainName";
export const SAAS_TENANT_ID = "saasTenantId";
export const SAAS_TENANT_INFO = "saasTenantInfo";
