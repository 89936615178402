import { combineReducers } from "redux";
import saasWalletReducer from "./SaasWalletSlice";
import saasVenturesReducer from "./SaasVenturesSlice";

const reducers = combineReducers({
  saasWallet: saasWalletReducer,
  saasVentures: saasVenturesReducer
});

export default reducers;

export * from "./context";
export * from "./SaasWalletSlice";
export * from "./SaasVenturesSlice";
