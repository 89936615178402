"use client";
import React, { Component } from "react";
import type { IkycInfo, IKycLevel } from "@aspen/model";
import { fecthKycInfo } from "@aspen/services";
import { updateUser } from "@aspen/store";
import { connect } from "react-redux";
import {
  certifiedKycLevel,
  isClient,
  KYC_CERTIFIED_LEVEL,
  KYC_CERTIFIED_STATUS,
  USER_AUTH,
  USER_ROLE_POWER
} from "@aspen/libs";
import ModalKycGuide from "../modalKycGuide/ModalKycGuide";

interface IUpateuser {
  kycInfo: IkycInfo;
}

interface IProps {
  kycInfo: IkycInfo;
  updateUser: (kycInfo: IUpateuser) => void;
  showToProfileBtn: boolean;
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

type IState = {
  showModal: boolean;
  noClose: boolean;
};

export const WithKYCInfo = (WrappedComponent) => {
  class KYCInfoLayout extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        showModal: false,
        noClose: false
      };
    }

    // level参数目前只会传入 1｜2
    showGuideModal: (level: IKycLevel, noClose?: boolean) => boolean = (level, noClose) => {
      if (certifiedKycLevel(level)) {
        return true;
      }
      this.setState({
        showModal: true,
        noClose: Boolean(noClose)
      });
      return false;
    };

    hideModal = () => {
      this.setState({
        showModal: false
      });
    };

    getKYCInfo = () => {
      fecthKycInfo().then((res) => {
        if (res?.code == "0") {
          // res.data是null是代表没有提交过kyc 此时设置redux
          const kycInfo = res?.data ?? null;
          this.props.updateUser({ kycInfo: res?.data });
          localStorage.setItem(KYC_CERTIFIED_LEVEL, kycInfo?.level);
          localStorage.setItem(KYC_CERTIFIED_STATUS, kycInfo?.status);
        }
      });
    };

    render() {
      // 高阶传递形式不优雅，且只有WithKYCInfo在使用ModalKycGuide
      const user_auth = (isClient && localStorage.getItem(USER_AUTH)) || "";
      const showToProfileBtn = USER_ROLE_POWER[user_auth]?.actionForOthers;
      return (
        <>
          <WrappedComponent
            {...this.props}
            getKYCInfo={this.getKYCInfo}
            kycInfo={this.props.kycInfo}
            showGuideModal={this.showGuideModal}
          />
          <ModalKycGuide
            noClose={this.state.noClose}
            visible={this.state.showModal}
            handleCancel={this.hideModal}
            showToProfileBtn={showToProfileBtn}
          />
        </>
      );
    }
  }

  const mapStateToProps = (state) => {
    const { kycInfo } = state.user;
    return {
      kycInfo
    };
  };
  const mapDispatchToProps = () => {
    return {
      updateUser: (payload) => updateUser(payload)
    };
  };

  return connect(mapStateToProps, mapDispatchToProps())(KYCInfoLayout);
};
