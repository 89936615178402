// 埋点事件对应的业务类型
export const GA_EVENT_BIZ = {
  Web_login_ck_login: "General",
  Web_login_To_xxx: "General",
  // "Web_{当前页面名字}_ck_navigation": "General",
  Web_xxx_To_xxx: "General",
  Web_home_To_profile: "KYC",
  Web_home_To_vip: "Vip",
  Web_home_ck_symbolPair: "Trade",
  Web_home_To_trade: "Trade",
  Web_home_To_noticeDetail: "Intelligence/Announcement",
  Web_home_ck_hideTotalAssets: "Wallet/Asset",
  Web_home_ck_showTotalAssets: "Wallet/Asset",
  Web_home_To_assets: "Wallet/Asset",
  "Web_home_To_wallet/deposit": "Wallet/Deposit",
  // "Web_home_To_yield": "Yield/ Flexible",
  Web_home_open_subscribeModal: "Yield/Flexible",
  Web_home_ck_changeAssetsMonth: "Wallet/Asset",
  Web_home_ck_depositaCrypto: "Wallet/Deposit",
  // "Web_home_To_wallet/deposit": "Wallet/Deposit",
  Web_home_ck_purchaseUSDT: "Trade",
  "Web_home_To_trade/USDT_USD": "Trade",
  Web_home_ck_trade: "Trade",
  "Web_home_To_trade/BTC_USDT": "Trade",
  Web_home_ck_dailyBriefing: "Intelligence/Daily Briefing",
  Web_home_To_briefing: "Intelligence/Daily Briefing",
  "Web_home_To_ventures/detail": "Venture",
  Web_home_ck_viewAllVenture: "Venture",
  Web_home_To_ventures: "Venture",
  "Web_home_To_strategies/detail": "Strategy",
  Web_home_ck_viewAllStrategy: "Strategy",
  Web_home_To_strategies: "Strategy",
  // "Web_home_To_yield": "Yield/DCA",
  "Web_home_To_yield/createDcaPlan": "Yield/DCA",
  // "Web_home_To_yield": "Yield/Flexible",
  // "Web_home_open_subscribeModal": "Yield/Flexible",
  // "Web_home_To_yield": "Yield/Flexible",
  Web_home_open_redeemModal: "Yield/Flexible",
  Web_home_open_switchAutoCompoundModal: "Yield/Flexible",
  Web_home_ck_submitAutoCompound: "Yield/Flexible",
  Web_home_ck_viewAllYield: "Yield",
  Web_home_To_yield: "Yield",

  Web_trade_ck_switchSymbolPair: "Trade",
  "Web_trade/{前交易对}_To_trade/{后交易对}": "Trade",
  Web_trade_ck_switchTimeTab: "Trade",
  Web_trade_ck_klineOrigin: "Trade",
  Web_trade_ck_klineTradingView: "Trade",
  Web_trade_ck_depth: "Trade",
  Web_trade_ck_kline: "Trade",
  Web_trade_ck_switchBuyOrSell: "Trade",
  Web_trade_ck_switchOrderType: "Trade",
  Web_trade_ck_placeOrder: "Trade",
  "Web_trade/SSV_USDC_To_wallet/deposit": "Deposit",
  Web_trade_ck_switchOrderList: "Trade",
  Web_trade_ck_refreshOpenOrder: "Trade",
  Web_trade_ck_refreshHistoryOrder: "Trade",
  Web_trade_open_orderDetailModal: "Trade",

  Web_yield_ck_switchYieldList: "Yield",
  Web_yield: "Yield",
  Web_yield_open_switchAutoCompoundModal: "Yield/Flexible",
  Web_yield_ck_submitAutoCompound: "Yield/Flexible",
  Web_yield_open_subscribeModal: "Yield",
  // "Web_yield_open_subscribeModal": "Yield/ Flexible",
  Web_yield_ck_submitSubscribe: "Yield",
  // "Web_yield_ck_submitSubscribe": "Yield/ Flexible",
  Web_yield_open_redeemModal: "Yield/Flexible",
  Web_yield_ck_submitRedeem: "Yield/Flexible",
  Web_yield_ck_createDCAPlan: "Yield/DCA",
  "Web_yield_To_yield/createDca": "Yield/DCA",
  "Web_yield_To_yield/subsFixed": "Yield/Fixed",

  "Web_yield/subsFixed_ck_submitSubscribe": "Yield/Fixed",
  // "Web_yield_open_subscribeModal": "Yield/Staking",
  // "Web_yield_ck_submitSubscribe": "Yield/Staking",
  // "Web_yield_open_subscribeModal": "Yield/Staking",
  // "Web_yield_ck_submitSubscribe": "Yield/Staking",
  // "Web_yield_To_yield/createDca": "Yield/DCA",
  "Web_yield/createDca_ck_switchStrategy": "Yield/DCA",
  "Web_yield/createDca_open_preExistPlan": "Yield/DCA",
  "Web_yield/createDca_open_previewPlan": "Yield/DCA",
  "Web_yield/createDca_ck_createPlan": "Yield/DCA",
  "Web_yield/createDca_To_yield/myDcaPlan": "Yield/DCA",
  // "Web_yield_To_yield/createDca": "Yield/DCA",
  "Web_yield/addInvestment_open_previewPlan": "Yield/DCA",
  "Web_yield/addInvestment_ck_createPlan": "Yield/DCA",
  // "Web_yield/createDca_To_yield/myDcaPlan": "Yield/DCA",

  Web_structured_ck_switchStructuredList: "Structured",
  Web_structured: "Structured",
  Web_structured_ck_switchProduct: "Structured",
  // "Web_structured_ck_switchProduct": "Structured/Dual Currency",
  Web_structured_ck_switchDirection: "Structured/Dual Currency",
  Web_structured_ck_subscribe: "Structured",
  // "Web_structured_ck_subscribe": "Structured/Dual Currency",
  Web_structured_To_dualSubscribe: "Structured/Dual Currency",
  Web_dualSubscribe_ck_submitSubscribe: "Structured/Dual Currency",
  Web_dualSubscribe_open_subsResult: "Structured/Dual Currency",
  // "Web_structured_ck_switchProduct": "Structured/Accumulator",
  Web_structured_ck_customizeAccumu: "Structured/Accumulator",
  Web_structured_ck_submitCustomization: "Structured/Accumulator",
  // "Web_structured_ck_subscribe": "Structured/Accumulator",
  Web_structured_To_accumuSubscribe: "Structured/Accumulator",
  Web_accumuSubscribe_ck_submitSubscribe: "Structured/Accumulator",
  Web_accumuSubscribe_open_suspended: "Structured/Accumulator",
  Web_accumuSubscribe_open_subsResult: "Structured/Accumulator",
  Web_snowSubscribe_ck_submitSubscribe: "Structured/Snowball",

  "Web_strategies_To_strategies/detail": "Strategy",
  "Web_strategy/detail_open_subsModal": "Strategy",
  "Web_strategy/detail_ck_submitSubscribe": "Strategy",
  "Web_strategy/detail_open_subsResult": "Strategy",
  "Web_strategy/detail_open_redeemModal": "Strategy",
  "Web_strategy/detail_ck_submitRedeem": "Strategy",
  "Web_strategy/detail_open_redeemResult": "Strategy",

  Web_venture_ck_switchVentureList: "Venture",
  Web_venture_open_listSecondary: "Venture",
  Web_venture_ck_submitListSecondary: "Venture",
  "Web_venture_To_venture/detail": "Venture",
  "Web_venture/detail_open_subsModal": "Venture",
  "Web_venture/detail_ck_submitSubscribe": "Venture",
  "Web_venture/detail_open_subsResult": "Venture",
  "Web_venture/detail_open_redeemModal": "Venture",
  "Web_venture/detail_ck_submitRedeem": "Venture",
  "Web_venture/detail_open_redeemResult": "Venture",

  Web_intelligence_ck_switchTab: "Intelligence",
  Web_insights_To_news: "Intelligence",
  Web_insights_ck_banner: "Intelligence/Aspen Insights",
  "Web_insights_To_briefing/detail": "Intelligence/Aspen Insights",
  Web_insights_ck_switchTab: "Intelligence/Aspen Insights",
  Web_insights_ck_loadmore: "Intelligence/Aspen Insights",
  Web_insights_ck_list: "Intelligence/Aspen Insights",
  // "Web_insights_To_briefing/detail": "Intelligence/Aspen Insights",
  Web_insights_To_noticeDetail: "Intelligence/Announcement",
  Web_insights_ck_reloadAnnouncement: "Intelligence/Announcement",
  "Web_insights_To_news/detail": "Intelligence/News",
  Web_insights_ck_moreNews: "Intelligence/News",
  // Web_insights_To_news: "Intelligence/News",
  "Web_insights_To_research/detail": "Intelligence/Research",
  Web_insights_ck_moreResearch: "Intelligence/Research",
  Web_insights_To_research: "Intelligence/Research",
  Web_insights_ck_moreExpert: "Intelligence/Expert",
  Web_insights_To_expert: "Intelligence/Expert",

  "Web_insights_To_expert/detail": "Intelligence/Expert",

  "Web_insights_To_expert/authorDetail": "Intelligence/Expert",
  Web_news_ck_banner: "Intelligence/News",
  "Web_news_To_news/detail": "Intelligence/News",
  // "Web_news_To_news/detail": "Intelligence/News",
  Web_news_ck_loadmore: "Intelligence/News",
  "Web_news_To_research/detail": "Intelligence/Research",
  Web_news_ck_moreResearch: "Intelligence/Research",
  Web_news_To_research: "Intelligence/Research",
  Web_news_ck_moreExpert: "Intelligence/Expert",
  Web_news_To_expert: "Intelligence/Expert",

  "Web_news_To_expert/detail": "Intelligence/Expert",

  "Web_news_To_expert/authorDetail": "Intelligence/Expert",
  Web_news_ck_moreDaily: "Intelligence/Aspen Insights",
  Web_news_To_insights: "Intelligence/Aspen Insights",
  "Web_news_To_briefing/detail": "Intelligence/Aspen Insights",
  Web_research_ck_list: "Intelligence/Research",
  "Web_research_To_research/detail": "Intelligence/Research",
  Web_research_ck_loadmore: "Intelligence/Research",
  Web_expert_ck_banner: "Intelligence/Expert",
  "Web_expert_To_expert/detail": "Intelligence/Expert",
  Web_expert_ck_follow: "Intelligence/Expert",
  Web_expert_ck_unfollow: "Intelligence/Expert",
  Web_expert_ck_viewAllExpert: "Intelligence/Expert",
  Web_expert_ck_featuredExpert: "Intelligence/Expert",
  "Web_expert_To_expert/authorDetail": "Intelligence/Expert",
  Web_expert_ck_articleList: "Intelligence/Expert",
  // "Web_expert_To_expert/detail": "Intelligence/Expert",
  Web_expert_ck_listExpert: "Intelligence/Expert",
  // "Web_expert_To_expert/authorDetail": "Intelligence/Expert",
  Web_expert_ck_moreFollowing: "Intelligence/Expert",
  "Web_expert_To_expert/list": "Intelligence/Expert",
  Web_expert_ck_followingArticle: "Intelligence/Expert",
  // "Web_expert_To_expert/detail": "Intelligence/Expert",
  Web_expert_ck_followingExpert: "Intelligence/Expert",
  // "Web_expert_To_expert/detail": "Intelligence/Expert",
  "Web_expert_To_briefing/detail": "Intelligence/Aspen Insights",
  Web_expert_ck_moreDaily: "Intelligence/Aspen Insights",
  Web_expert_To_insights: "Intelligence/Aspen Insights",
  "Web_expert_To_research/detail": "Intelligence/Research",
  Web_expert_ck_moreResearch: "Intelligence/Research",
  Web_expert_To_research: "Intelligence/Research",
  "Web_expert_To_news/detail": "Intelligence/News",
  Web_expert_ck_moreNews: "Intelligence/News",
  Web_expert_To_news: "Intelligence/News",

  Web_history_ck_switchTab: "History",
  Web_history_spot: "History/Spot",
  Web_history_yield: "History/Yield",
  Web_history_structured: "History/Structured",
  Web_history_strategies: "History/Strategy",
  Web_history_ventures: "History/Venture",
  Web_history_ck_switchYieldTab: "History/Yield",
  Web_history_flexible: "History/Yield",
  Web_history_fixed: "History/Yield",
  Web_history_staking: "History/Yield",
  Web_history_ck_switchStructuredTab: "History/Structured",

  Web_wallet_ck_hideAssets: "Wallet",
  Web_wallet_ck_showAssets: "Wallet",
  "Web_wallet_To_wallet/deposit": "Wallet/Deposit",
  Web_wallet_To_assets: "Wallet/Asset",
  "Web_wallet_To_wallet/vault": "Cold Wallet",
  Web_wallet_open_storageVault: "Cold Wallet",
  "Web_wallet_To_wallet/vault/storage": "Cold Wallet",
  Web_wallet_ck_switchTab: "Wallet",
  "Web_wallet_To_history/accountDetail": "Wallet/Spot",
  Web_wallet_ck_deposit: "Wallet/Deposit",
  Web_wallet_open_depositUSDCMethod: "Wallet/Deposit",
  // "Web_wallet_To_wallet/deposit": "Wallet/Deposit",
  // "Web_wallet_ck_deposit": "Wallet/Deposit",
  // "Web_wallet_To_wallet/deposit": "Wallet/Deposit",
  Web_wallet_ck_withdraw: "Wallet/Withdraw",
  Web_wallet_open_withdrawUSDCMethod: "Wallet/Withdraw",
  "Web_wallet_To_wallet/withdraw": "Wallet/Withdraw",
  // "Web_wallet_ck_withdraw": "Wallet/Withdraw",
  // "Web_wallet_To_wallet/withdraw": "Wallet/Withdraw",
  Web_wallet_ck_switchYieldTab: "Wallet/Yield",
  Web_wallet_flexible: "Wallet/Yield",
  Web_wallet_fixed: "Wallet/Yield",
  Web_wallet_staking: "Wallet/Yield",
  Web_wallet_To_yield: "Yield",
  // "Web_wallet_To_yield": "Yield/Flexible",
  Web_wallet_open_subscribeModal: "Yield/Flexible",
  // "Web_wallet_To_yield": "Yield/Flexible",
  // Web_wallet_open_redeemModal: "Yield/Flexible",
  Web_wallet_open_fixedDetail: "Yield/Fixed",
  Web_wallet_open_cancelFixedModal: "Yield/Fixed",
  Web_wallet_ck_submitCancelFixed: "Yield/Fixed",
  Web_wallet_open_stakingDetail: "Yield/Staking",
  Web_wallet_ck_switchRestake: "Yield/Staking",
  // "Web_wallet_To_yield": "Yield/Staking",
  Web_wallet_open_stakeModal: "Yield/Staking",
  // "Web_wallet_To_yield": "Yield/Staking",
  Web_wallet_open_unstakeModal: "Yield/Staking",
  Web_wallet_open_notSupportRedeem: "Yield/Staking",
  "Web_wallet_To_yield/myDcaPlan": "Yield/DCA",
  "Web_yield/myDcaPlan_ck_viewDCADetail": "Yield/DCA",
  "Web_yield/myDcaPlan_open_cancelModal": "Yield/DCA",
  "Web_yield/myDcaPlan_ck_confirmCancel": "Yield/DCA",
  "Web_yield/myDcaPlan_To_yield/dcaHistory": "Yield/DCA",
  "Web_yield/dcaHistory_open_historyDetail": "Yield/DCA",
  Web_wallet_ck_switchStructuredTab: "Structured",
  Web_wallet_To_history: "Structured",
  Web_wallet_open_dualDetail: "Structured/Dual Currency",
  Web_wallet_open_accumulatorDetail: "Structured/Accumulator",
  Web_wallet_open_subsModal: "Strategy",
  Web_wallet_ck_submitSubscribe: "Strategy",
  Web_wallet_open_subsResult: "Strategy",
  // Web_wallet_open_redeemModal: "Strategy",
  Web_wallet_ck_submitRedeem: "Strategy",
  Web_wallet_open_redeemResult: "Strategy",

  Web_wallet_open_subscribeVenture: "Venture",
  Web_wallet_ck_submitVentureSubscribe: "Venture",
  Web_wallet_open_ventureSubsResult: "Venture",
  // "Web_wallet_open_subscribeVenture": "Venture",
  Web_wallet_ck_submitVentureRedeem: "Venture",
  Web_wallet_open_ventureRedeemResult: "Venture",
  "Web_wallet/withdraw_open_selectAddress": "Wallet/Withdraw",
  "Web_wallet/withdraw_ck_whitelistSetting": "Wallet/Withdraw",
  "Web_wallet/withdraw_To_wallet/withdraw/addresses": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_whitelist": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_remove": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_confirmRemove": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_delete": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_confirmDelete": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_open": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_off": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_confirmOpen": "Wallet/Withdraw",
  "Web_wallet/withdraw/address_ck_confirmOff": "Wallet/Withdraw",

  Web_assets_ck_changeAssetsMonth: "Wallet/Asset",
  Web_assets_ck_changePnlDate30: "Wallet/Asset",
  Web_assets_ck_changePnlDate7: "Wallet/Asset",
  Web_assets_ck_changeCustomDate: "Wallet/Asset",

  Web_profile_open_changePWModal: "General",
  Web_profile_ck_changePWSuccess: "General",
  Web_profile_To_login: "General",
  Web_profile_open_bindGoogleModal: "General",
  Web_profile_open_viewGoogleKey: "General",
  Web_profile_ck_confirmBindGoogle: "General",
  Web_profile_open_unbindGoogleModal: "General",
  Web_profile_ck_confirmUnBindGoogle: "General",
  Web_profile_ck_invitationCode: "General",
  Web_profile_ck_invitationLink: "General",
  Web_profile_open_switchServiceModal: "General",
  Web_profile_ck_confirmSwitchService: "General",
  Web_KycVerification_ck_submitIndSur: "General/KYC",
  Web_KycVerification_ck_submitInsSur: "General/KYC",
  Web_KycVerification_ck_submitInsDoc: "General/KYC",
  Web_KycVerification_ck_submitRiskInd: "General/KYC",
  Web_KycVerification_ck_submitRiskIns: "General/KYC",

  Web_download_openDownloadPage: "General",
  Web_download_downloadGoogleApp: "General",
  Web_download_downloadAppleApp: "General",
  Web_download_switchLanguage_zh: "General",
  Web_download_switchLanguage_en: "General"
};
