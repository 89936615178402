/* eslint-disable no-return-await */
import type { ICommonCacheKeys } from "@aspen/model/index";
import { COMMON_CACHE_PRIORTY_KEYS } from "@aspen/model/index";
import { generateDataCacheKey, sessionStorageUtils } from "@aspen/libs";
import { useQuery } from "@tanstack/react-query";
import {
  fetchEventsList,
  aspenApi as api,
  getEventsDetail,
  getVenturePosition,
  getVentureDetail,
  newFixedProductsList,
  getFundDetail,
  getFundList
} from "@aspen/services";
import localforage from "localforage";

/**
 * @param key 唯一key名称
 * @param params 请求参数
 * @param useQueryExtraOptions useQuery/useMutation/useInfiniteQuery/useQueryClient等额外参数
 */

const emptyFunction = () => {
  return { isPending: false, error: "", data: undefined, refetch: () => {} };
};

function fecthers<T>(options: { key: ICommonCacheKeys; params: any; useQueryExtraOptions?: any }) {
  const { key, params, useQueryExtraOptions } = options;

  if (!key) {
    return emptyFunction;
  }

  // 获取placeholderData
  const getPlaceholderData = async (
    queryKey: string,
    useQueryExtraOptions?: any,
    isSessionStatus?: boolean
  ) => {
    // 注意未开启自动执行时不返回placeholderData的值，避免和一个页面的多个相同请求的placeholderData覆盖
    if (!useQueryExtraOptions?.enabled) return undefined;
    let placeholderData: string | undefined | null = "";
    if (isSessionStatus) {
      const cacheData = sessionStorageUtils.getItem(queryKey.toString());
      placeholderData = cacheData && JSON.parse(cacheData);
    } else {
      const cacheData = await localforage.getItem(queryKey.toString());
      // todo 下面API方法中存在useQuery hook，所以调用getPlaceholderData方法时，不能存在异步情况；
      placeholderData = null; //cacheData && JSON.parse(cacheData);
    }
    return placeholderData;
  };

  // 获取活动列表
  const getEventList = () => {
    const apiPath = api.events.list;
    const _params = { limit: 100, type: "", ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: _params });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await fetchEventsList(_params).then((res) => {
          if (res?.code == "0") {
            return res?.data;
          }
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  // 获取活动详情
  const getEventDetailInfo = () => {
    const apiPath = api.events.detail;
    const _params = { ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: _params });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getEventsDetail(_params).then((res) => {
          if (res?.code == "0") {
            return res?.data;
          }
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  // 获取新定期理财列表
  const getFixedYieldList = () => {
    const apiPath = api.savings.newFixed.productList;
    const _params = { limit: 10, ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: _params });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await newFixedProductsList(_params).then((res) => {
          if (res?.code == "0") {
            return res?.data;
          }
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  // 获取活动列表
  const fetchFundList = () => {
    const apiPath = api.fund.fundList;
    const _params = { limit: 100, ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: _params });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getFundList(_params).then((res) => {
          if (res?.code == "0") {
            return res?.data;
          }
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  // 获取venture详情
  const fetchVentureDetail = () => {
    const apiPath = api.ventures.ventureDetail;
    const _params = { ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: JSON.stringify(_params) });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getVentureDetail(_params).then((res) => {
          return res;
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  // 获取fund详情
  const fetcherFundDetail = () => {
    const apiPath = api.fund.fundDetail;
    const _params = { ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: JSON.stringify(_params) });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getFundDetail(_params).then((res) => {
          return res;
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  // 获取venture产品持仓
  const getVenturePositions = () => {
    const apiPath = api.ventures.venturePosition;
    const _params = { ...params };
    const queryKey = generateDataCacheKey({ api: apiPath, params: _params });
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);

    const { isPending, error, data, isPlaceholderData, isLoading, refetch } = useQuery({
      queryKey: [queryKey, Object.values(_params)],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getVenturePosition().then((res) => {
          if (res?.code == "0") {
            return res;
          }
        });
      }
    });

    const isSessionStatus = _params?.nexToken;

    if (isSessionStatus) {
      return {
        isPending,
        error,
        data,
        refetch,
        isPlaceholderData,
        sessionStorageKey: queryKey,
        isLoading
      };
    }
    return { isPending, error, data, refetch, isPlaceholderData, queryKey, isLoading };
  };

  let fetcher;
  switch (key) {
    case COMMON_CACHE_PRIORTY_KEYS.eventsList:
      fetcher = getEventList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.eventsDetail:
      fetcher = getEventDetailInfo;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.yieldFixedProducts:
      fetcher = getFixedYieldList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.ventureDetail:
      fetcher = fetchVentureDetail;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.funds:
      fetcher = fetchFundList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.fundsDetail:
      fetcher = fetcherFundDetail;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.venturePositions:
      fetcher = getVenturePositions;
      break;
    default:
      fetcher = emptyFunction;
  }
  return fetcher;
}

export { fecthers };
