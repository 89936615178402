import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import styles from "@aspen/theme/Venture.module.less";
import { BENCHMARK_CURRENCY, validAmount, i18nUtil } from "@aspen/libs";
import { NumericInput, WithTrimInput } from "@aspen/ui";
import { Select } from "antd";
import VentureSlider from "./VentureSlider";

interface IProps {
  setSubmitDisabled: (submitDisabled: boolean) => void;
}

const OtherVentureSell = (props: IProps, ref) => {
  const intl = i18nUtil.t();
  const { setSubmitDisabled } = props;
  const [otherVentureName, setOtherVentureName] = useState<string>("");
  const [otherAssetType, setOtherAssetType] = useState<string>();
  const [listAmount, setListAmount] = useState<string>("");
  const [discountRate, setDiscountRate] = useState<[number, number]>([0, 200]);

  // 将 state 对象添加到引用中
  useImperativeHandle(ref, () => ({
    otherVentureName,
    otherAssetType,
    listAmount,
    discountRate: `${discountRate[0]}% ~ ${discountRate[1]}%`
  }));

  const assetTypesOption = useMemo(() => {
    return [
      {
        id: "Fund",
        name: intl["venture.capital.0"]
      },
      {
        id: "Equity",
        name: intl["venture.capital.1"]
      },
      {
        id: "Token",
        name: intl["venture.capital.3"]
      },
      {
        id: "NFT",
        name: intl["venture.capital.4"]
      },
      {
        id: "Others",
        name: intl["venture.others"]
      }
    ];
  }, []);

  return (
    <>
      <div className={styles.flexColumn}>
        <span className={styles.inputTitleText}>{intl["ventures.shares.venture.other.name"]}</span>
        <WithTrimInput
          bordered={false}
          value={otherVentureName}
          placeholder={intl["ventures.shares.input.placeholder"]}
          className={styles.amountInput}
          onChange={(event) => {
            setOtherVentureName(event.target.value);
            setSubmitDisabled(!(event.target.value && otherAssetType && listAmount));
          }}
        />
      </div>
      <div className={styles.flexColumn}>
        <span className={styles.inputTitleText}>{intl["ventures.shares.select.title"]}</span>
        <Select
          value={otherAssetType}
          className={styles.selectArea}
          placeholder={intl["ventures.shares.select.holder"]}
          onChange={(value) => {
            setOtherAssetType(value);
            setSubmitDisabled(!(value && listAmount && otherVentureName));
          }}
          bordered={false}
          size="large"
          options={assetTypesOption.map((data, index) => ({
            item: data,
            value: data.id,
            label: data.name
          }))}
        />
      </div>
      <div className={styles.flexColumn}>
        <span className={styles.inputTitleText}>{intl["ventures.shares.list.amount"]}</span>
        <NumericInput
          bordered={false}
          value={listAmount}
          placeholder={intl["ventures.shares.input.placeholder"]}
          className={styles.amountInput}
          onChangeEvent={(value) => {
            const _value = validAmount(value?.toString());
            if (_value === false) return;
            setListAmount(_value);
            setSubmitDisabled(!(value && otherAssetType && otherVentureName));
          }}
          addonAfter={BENCHMARK_CURRENCY}
        />
      </div>
      {/*目前市场行情比较好，销售不希望向用户传达Venture可以用Discount（折扣）申购或出售，隐藏理想贴现率*/}
      {/*<div className={styles.flexColumn}>*/}
      {/*    <span className={styles.inputTitleText}>{intl["ventures.shares.discount.rate"]}</span>*/}
      {/*    <VentureSlider*/}
      {/*        defaultValue={discountRate}*/}
      {/*        onAfterChange={setDiscountRate}*/}
      {/*    />*/}
      {/*</div>*/}
    </>
  );
};

export default React.memo(forwardRef(OtherVentureSell));
