import dynamic from "next/dynamic";

export * from "./modal";
export * from "./table";
export * from "./withdraw";
export * from "./yield";
export const History = dynamic(() => import("./History"), {
  ssr: false
});

export const Dashboard = dynamic(() => import("./Dashboard"), {
  ssr: false
});

export const VaultHistory = dynamic(() => import("./VaultHistory"), {
  ssr: false
});
export const AdgmInputArea = dynamic(() => import("./AdgmInputArea"), {
  ssr: false
});
export const WalletCategoryDetails = dynamic(() => import("./WalletCategoryDetails"), {
  ssr: false
});
export const ModalBankAccountStatus = dynamic(
  () => import("./modalBankAccountStatus/ModalBankAccountStatus"),
  {
    ssr: false
  }
);
