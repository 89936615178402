import React from "react";
import type { TableProps } from "antd";
import { Table } from "antd";
import styles from "./index.module.less";

export interface PaginationTableProps extends TableProps<any> {
  className?: string;
}

export const PaginationTable: React.FC<PaginationTableProps> = React.memo((props) => {
  const { ...restProps } = props;

  // 区分表格奇偶行
  const getRowClassName = (record, index) => {
    let className = "";
    className = index % 2 === 0 ? styles.oddRow : styles.evenRow;
    return className;
  };

  return <Table className={styles.paginationTable} rowClassName={getRowClassName} {...restProps} />;
});
