import { devConfig } from "./host_dev";
import { betaConfig } from "./host_beta";
import { prodConfig } from "./host_prod";
import { preConfig } from "./host_pre";

export const aspenConfig = {
  dev: devConfig,
  beta: betaConfig,
  pre: preConfig,
  prod: prodConfig
};
