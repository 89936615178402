import React, { memo } from "react";
import styles from "@aspen/theme/Event.module.less";
import { i18nUtil } from "@aspen/libs";
import type { IEventStatus } from "@aspen/model";
import clsx from "clsx";

interface IProps {
  status: IEventStatus | undefined;
  style?: React.CSSProperties;
  isMobile?: boolean;
}
const EventTagView: React.FC<IProps> = (props) => {
  const { status, style, isMobile } = props;
  const intl = i18nUtil.t();
  let text = "";
  let cssProperties = "coming";
  switch (status) {
    case "coming":
      text = intl["event.status.coming"];
      cssProperties = "coming";
      break;
    case "inProgress":
      text = intl["event.status.progress"];
      cssProperties = "inProgress";
      break;
    case "end":
      text = intl["event.status.ended"];
      cssProperties = "end";
      break;
  }

  return text ? (
    <div
      className={clsx(styles.tag, styles[cssProperties], isMobile ? styles.mobile : styles.normal)}
      style={style}>
      {text}
    </div>
  ) : null;
};
export const EventTag = React.memo(EventTagView, (prevProps, nextProps) => {
  return prevProps.status === nextProps.status;
});
