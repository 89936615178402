import React from "react";
import { Column, ColumnConfig } from "@ant-design/plots";
import { ShapeAttrs } from "@antv/g-base";
import variables from "@aspen/theme/variables.module.less";

interface IProps<E> {
  height?: number;
  xField: string;
  yField: string;
  list: Array<E>;
  xTickCount?: number;
  yTickCount?: number;
  xLabelStyle?: ShapeAttrs;
  yLabelStyle?: ShapeAttrs;
  yGridLineStyle?: ShapeAttrs;
  lineWidth?: number;
  yAxisPosition?: "top" | "bottom" | "right" | "left";
  yAxisFormatter?: (text: string) => {};
  tooltipValueFormatter?: (text: string) => string;
}

interface IState {}

/**
 * 柱状图
 * 关于配置概念可以参考
 * https://charts.ant.design/zh/examples/column/basic#basic
 * https://g2plot.antv.vision/zh/docs/api/plots/column
 */
export class ColumnChart<T> extends React.PureComponent<IProps<T>, IState> {
  constructor(props: IProps<T>) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const {
      height,
      xField,
      yField,
      list = [],
      xTickCount,
      yTickCount,
      xLabelStyle = {},
      yLabelStyle = {},
      yGridLineStyle = {},
      yAxisPosition
    } = this.props;

    const overZeroX = list.filter((item) => item[yField] > 0).map((item) => item[xField]) || [];

    const config: ColumnConfig & React.RefAttributes<unknown> = {
      height: height,
      autoFit: !height,
      // @ts-ignore
      data: list,
      xField: xField,
      yField: yField,
      columnWidthRatio: list?.length > 15 ? 0.7 : 0.6,
      // color: "l(90) 0:#ff0000 1:rgba(218, 73, 49, 0.3)",
      // colorField: 'type', // 部分图表使用 seriesField
      color: (data) => {
        if (overZeroX.includes(data[xField])) {
          return "l(90) 0:rgba(19, 133, 133, 0.71) 1:rgba(19, 133, 133, 0.18)";
        } else {
          return "l(90) 0:rgba(218, 73, 49, 0.18) 1:rgba(218, 73, 49, 0.56)";
        }
      },
      theme: {
        fontFamily: "Common-Regular"
      },
      tooltip: {
        // @ts-ignore
        showTitle: false,
        enterable: true,
        showContent: true,
        formatter: (datum) => {
          return {
            name: datum[xField],
            value: this.props?.tooltipValueFormatter
              ? this.props?.tooltipValueFormatter(datum[yField])
              : datum[yField]
          };
        },
        domStyles: {
          "g2-tooltip": {
            backgroundColor: variables.tooltipBg,
            boxShadow: "none",
            fontFamily: "Common-Regular"
          },
          "g2-tooltip-list-item": { color: variables.white }
        }
      },
      xAxis: {
        tickCount: xTickCount || 10,
        line: null,
        tickLine: null,
        label: {
          style: {
            fontSize: 13,
            lineHeight: 16.5,
            fill: "#ffffff",
            fillOpacity: 0.45,
            cursor: "pointer",
            fontFamily: "Common-Regular",
            ...xLabelStyle
          },
          offset: 30
        }
      },
      yAxis: {
        tickCount: yTickCount || 10,
        position: yAxisPosition,
        line: null,

        label: {
          formatter: this.props?.yAxisFormatter ?? undefined,
          style: {
            fontSize: 13,
            lineHeight: 16.5,
            fill: "#ffffff",
            fillOpacity: 0.45,
            cursor: "pointer",
            fontFamily: "Common-Regular",
            ...yLabelStyle
          }
        },
        grid: {
          line: {
            style: {
              stroke: "#ffffff",
              lineWidth: 1,
              lineDash: [4, 5],
              strokeOpacity: 0.15,
              cursor: "pointer",
              ...yGridLineStyle
            }
          }
        }
      }
    };
    return <Column {...config} />;
  }

  componentDidMount() {}

  componentWillUnmount() {}
}
