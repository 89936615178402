import { useContext, useMemo } from "react";
import { SaasContext } from "@aspen/store";
import { getTenantInfo } from "@aspen/libs";

export const useSaasTenant = () => {
  const { tenantUpdate } = useContext(SaasContext) ?? {};
  return useMemo(() => {
    return getTenantInfo();
  }, [tenantUpdate]);
};
