import dynamic from "next/dynamic";

export const AccountInput = dynamic(() => import("./AccountInput"), {
  ssr: false
});
export const EmailInputGroup = dynamic(() => import("./EmailInputGroup"), {
  ssr: false
});
export const PhoneInputGroup = dynamic(() => import("./PhoneInputGroup"), {
  ssr: false
});
export const CodeFiledInput = dynamic(() => import("./CodeFiledInput"), {
  ssr: false
});
