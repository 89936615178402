import type { IDynamicLinkParameters, IEnvConfig, IEnvs } from "@aspen/model/index";
import { getEnvConfig } from "./common";
/**
 * 生成动态链接
 * @param {IDynamicLinkParameters}
 * 参考： https://dev.appsflyer.com/hc/docs/react-native-plugin
 * 注意！！：参数变化对其他地方的影响，比如：分享、小工具、动态链接、app内部跳转等
 */
const buildDynamicLink = (params: IDynamicLinkParameters, inAppMessage?: boolean): string => {
  const { webPath, routeName, routeParams, env, googleAnalyticsParameters } = params;
  let envConfig: IEnvConfig = getEnvConfig(env);
  const { webDomain, domainUriPrefix, fallbackUrl, packageName, appScheme } = envConfig;

  const domain = domainUriPrefix;
  const obj: { [key: string]: string } = {
    af_ios_url: fallbackUrl.ios,
    af_android_url: fallbackUrl.android,
    af_web_dp: webPath ? webPath : webDomain,
    af_xp: "social",
    pid: "deeplink",
    af_dp: `${appScheme}://open`,
    deep_link_value: "openPage",
    deep_link_sub1: encodeURIComponent(
      `routeName=${routeName}&${routeParams}${googleAnalyticsParameters ? `&${googleAnalyticsParameters}` : ""}`
    )
  };

  if (inAppMessage) {
    obj.inAppMessage = "true";
  }

  const url = new URL(domain);
  Object.keys(obj).map((key) => {
    url.searchParams.set(key, obj[key]);
  });

  return url.toString();
};

export { buildDynamicLink };
