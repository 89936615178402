import React from "react";
import Head from "next/head";
import { webDomain } from "@aspen/libs";

export interface IProps {
  title?: string;
  description?: string;
  image?: string;
}

const DEAULT_OG_IMAGE = `${webDomain}/images/ogdefault.png`;
export const HeadRender: React.FC<IProps> = ({ title, description, image }) => (
  <Head>
    <title>{title}</title>
    <meta name="title" property="og:title" content={title} />
    <meta name="description" property="og:description" content={description || title} />
    <meta name="image" property="og:image" content={image ?? DEAULT_OG_IMAGE} />
  </Head>
);
