"use client";
import React, { Component } from "react";
import type {
  IMarketPushItemType,
  IPrecisions,
  IOrderBookItem,
  IDepthItem,
  IDepth,
  ISymbolDataType,
  ISymbolInfo,
  IInjectProps
} from "@aspen/model/index";
import { BRAND } from "@aspen/model/index";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import {
  updateOrderBook,
  updateSymbolInfo,
  updateOfflineSymbolInfo,
  updateSymbolNames,
  updateDepth,
  updateOpenOrder,
  updateAllSymbolPrecision
} from "@aspen/store";
import { fecthSymbolList, fetchOfflineSymbolList } from "@aspen/services/index";
import {
  websocket,
  formatFixNumString,
  createUUID,
  formatBugsnagMessage,
  getCurrentPathName,
  getSessionStorageTokenKey,
  AGENT,
  COIN_CODE,
  HOME_SYMBOLS,
  ADGM_HOME_SYMBOLS,
  HOME_PATH,
  TRADE_PATH,
  STRUCTURED_PATH,
  STRUCTURED_SYMBOLS,
  isClient,
  systemDetection,
  BTC_USDT,
  BTC_USDC
} from "@aspen/libs/index";
import { WS } from "@kikitrade/api-gateway-client";
import Bugsnag from "@bugsnag/js";
import { cloneDeep, isEqual } from "lodash-es";
import { withRouter } from "next/router";

const mapStateToProps = (state) => {
  const { marketSymbolsList, offlineSymbolsList, orderBook, depthData, activeSymbolPrecisions } =
    state.marketSpotAndTradeInfo;
  return {
    marketSymbolsList, // 现货币种价格/交易量等信息
    offlineSymbolsList, // 离线的盘口精度信息
    orderBook, // 当前币种的orderbook数据
    depthData, // 当前币种的深度数据
    activeSymbolPrecisions
  };
};
const mapDispatchToProps = () => {
  return {
    updateSymbolInfo: (payload) => updateSymbolInfo(payload),
    updateOfflineSymbolInfo: (payload) => updateOfflineSymbolInfo(payload),
    updateSymbolNames: (payload) => updateSymbolNames(payload),
    updateOrderBook: (payload) => updateOrderBook(payload),
    updateDepth: (payload) => updateDepth(payload),
    updateOpenOrder: (payload) => updateOpenOrder(payload),
    updateAllSymbolPrecision: (payload) => updateAllSymbolPrecision(payload)
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type IFiatSpotPropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends IInjectProps, IFiatSpotPropsFromRedux {
  [key: string]: any; // 保留any，HOC组件，父组件传过来的props可能是任何类型
}
export interface IPropsWithFiatAndSpotTrade {
  registerWS: () => void;
  unregisterWS: () => void;
  updateAllSymbolInfoList: (data: IMarketPushItemType[]) => void;
  updateCurrentOrderBook: (coinCode: string, data: IDepth) => void;
  sendOrderBookWsData: (symbol: string, fromSymbol?: string) => void;
}

type WrappedProps = {
  needSymbols: boolean;
  needWebsocket: boolean;
};

type IState = {
  lockConnect: boolean;
  wsRoom: object;
  coinCode: string;
  lastWsPushTime: number;
  marketItemUpdateTs: number;
  componentDidMount: boolean;
  wsErrorCount: number; //wsorderbook连续推送相同数据次数
};

const newMarketData = {};

export const WithFiatAndSpotTrade = (WrappedComponent, defaultProps?: WrappedProps) => {
  class WithFiatAndSpotTrade extends Component<IProps, IState> {
    system = systemDetection();
    constructor(props: IProps) {
      super(props);
      this.state = {
        componentDidMount: false,
        lastWsPushTime: new Date().getTime(),
        lockConnect: false,
        coinCode: this.system === BRAND ? BTC_USDT : BTC_USDC,
        marketItemUpdateTs: new Date().getTime(),
        wsRoom: {
          [this.system ? BTC_USDT : BTC_USDC]: {
            room: "orderbook",
            symbol: this.system ? BTC_USDT : BTC_USDC,
            fromSymbol: ""
          }
        },
        wsErrorCount: 0
      };

      this.fecthSymboInfoList = this.fecthSymboInfoList.bind(this);
      this.fetchOfflineSymbolInfoList = this.fetchOfflineSymbolInfoList.bind(this);
      this.websocketListener = this.websocketListener.bind(this);
      this.registerWS = this.registerWS.bind(this);
      this.unregisterWS = this.unregisterWS.bind(this);
      this.updateAllSymbolInfoList = this.updateAllSymbolInfoList.bind(this);
      this.updateCurrentOrderBook = this.updateCurrentOrderBook.bind(this);
      this.updateOb = this.updateOb.bind(this);
      this.sendOrderBookWsData = this.sendOrderBookWsData.bind(this);
      this.getCurrentCoinCode = this.getCurrentCoinCode.bind(this);
    }

    getCurrentCoinCode: () => string = () => {
      const lcoalState = localStorage.getItem(COIN_CODE);
      return lcoalState ?? "";
    };

    fecthSymboInfoList: () => void = async () => {
      fecthSymbolList()
        .then((res) => {
          if (res?.code == "0") {
            if (res?.data?.length < 1) {
              // 盘口列表数据返回空，上报错误
              Bugsnag.notify(
                new Error(
                  formatBugsnagMessage(
                    `Error market/symbols API no data. \n${JSON.stringify(res, null, "\t")}`
                  )
                )
              );
            } else {
              // 盘口列表数据不能为空
              this.updateAllSymbolInfoList(res?.data);
              this.props.updateAllSymbolPrecision(res?.data);
            }
          }
        })
        .finally(() => {});
    };

    // 获取离线盘口信息
    fetchOfflineSymbolInfoList: () => void = async () => {
      fetchOfflineSymbolList().then((res) => {
        if (res?.code == "0") {
          this.updateOfflineSymbolInfoList(res?.data ?? []);
        }
      });
    };

    updateCurrentOrderBook: (coinCode: string, data: IDepth) => void = (coinCode, data) => {
      if (!this.state.componentDidMount) return;
      this.setState({
        coinCode
      });
      this.updateOb(data);
      this.props.updateDepth && this.props.updateDepth({ depthData: this.reOrgDepthData(data) });
    };

    // @ts-ignore
    reOrgDepthData: (data: { bids: Array<IDepthItem>; asks: Array<IDepthItem> }) => IDepth =
      (data: { bids: Array<IDepthItem>; asks: Array<IDepthItem> }) => {
        //深度线需要转化为number 否则报错
        const numData = JSON.parse(JSON.stringify(data));
        const bids: [number, number][] = [];
        let asks: [number, number][] = [];
        numData?.asks.map((item: IDepthItem) => {
          asks.push([Number(item.p), Number(item.v)]);
        });
        numData?.bids.map((item: IDepthItem) => {
          bids.push([Number(item.p), Number(item.v)]);
        });
        return {
          bids,
          asks
        };
      };

    updateOb: (dataAll: IOrderBookItem) => void = (dataAll: IOrderBookItem) => {
      const data = dataAll;
      data.asks = data?.asks?.slice(0, 10) || [];
      data.bids = data?.bids?.slice(0, 10) || [];
      const coinCode = this.getCurrentCoinCode();
      const coinDetail = this.props?.activeSymbolPrecisions?.[coinCode];
      let sumBids = 0;
      let sumAsks = 0;
      const ask = {
        pLenth: coinDetail?.tradePrecision ?? 0,
        vLenth: coinDetail?.tradeInputPrecision ?? 5
      };
      const bid = {
        pLenth: coinDetail?.tradePrecision ?? 0,
        vLenth: coinDetail?.tradeInputPrecision ?? 5
      };
      data?.bids.forEach((item: { v: string; p: string }) => {
        sumBids += parseFloat(item.v);
      });
      data?.asks.forEach((item: { v: string; p: string }) => {
        sumAsks += parseFloat(item.v);
      });
      const max = Math.max(sumBids, sumAsks);
      data.bids.forEach(
        (item: { v: string; p: string; sum: number; barPercent: number }, index: number) => {
          // @ts-ignore
          data.bids[index].sum = Number(item.v) + Number((data.bids[index - 1] || {})?.sum ?? 0);
          // @ts-ignore
          data.bids[index].p = formatFixNumString(item.p, bid.pLenth).toString();
          // @ts-ignore
          data.bids[index].v = formatFixNumString(item.v, bid.vLenth).toString();
          // @ts-ignore
          data.bids[index].barPercent =
            // @ts-ignore
            Number((data.bids[index].sum * 100) / max) >= 100
              ? 100
              : // @ts-ignore
                Number((data.bids[index].sum * 100) / max);
        }
      );
      data?.asks.forEach(
        (item: { v: string; p: string; sum: number; barPercent: number }, index: number) => {
          // @ts-ignore
          data.asks[index].sum = Number(item.v) + Number((data.asks[index - 1] || {})?.sum ?? 0);
          // @ts-ignore
          data.asks[index].p = formatFixNumString(item.p, ask.pLenth).toString();
          // @ts-ignore
          data.asks[index].v = formatFixNumString(item.v, ask.vLenth).toString();
          // @ts-ignore
          data.asks[index].barPercent =
            // @ts-ignore
            Number((data.asks[index].sum * 100) / max) >= 100
              ? 100
              : // @ts-ignore
                Number((data.asks[index].sum * 100) / max);
        }
      );

      if (
        isEqual(data?.asks, this.props.orderBook.asks.slice().reverse()) &&
        isEqual(data?.bids, this.props.orderBook.bids)
      ) {
        // 连续推送五次相同的数据时 上报错误
        this.setState({
          wsErrorCount: this.state.wsErrorCount + 1
        });
        // if (this.state.wsErrorCount == 5) {
        //   Bugsnag.notify(
        //     new Error(
        //       formatBugsnagMessage(
        //         `received the same ws message orderbook: ${coinCode}\n${JSON.stringify(
        //           data,
        //           null,
        //           "\t"
        //         )}`,
        //         "ws-received-orderbook-message"
        //       )
        //     )
        //   );
        // }
      } else {
        // 推送补同的数据时 清除重复数
        this.setState({
          wsErrorCount: 0
        });
        this.props.updateOrderBook({
          orderBook: {
            symbol: data.symbol,
            bids: data?.bids ?? [], // 买方深度
            asks: data?.asks?.slice().reverse() ?? [] // 卖方深度
          }
        });
      }
    };

    sendOrderBookWsData: (symbol: string, fromSymbol?: string) => void = (symbol, fromSymbol) => {
      // 暂时不展示ob了， 故统一在此不订阅orderbook room
      return;
      if (!isClient) return;
      const room = this.state.wsRoom;
      let eventData = {};
      if (symbol) {
        eventData = {
          room: "orderbook",
          symbol,
          fromSymbol
        };
        this.setState({
          coinCode: symbol
        });
      }
      // this.updateOb({ asks: [], bids: [] });
      room[symbol] = eventData;
      const interval: NodeJS.Timer = setInterval(() => {
        if (!location.href.includes("trade") || !this.state.componentDidMount) {
          // @ts-ignore
          clearInterval(interval);
          return;
        }
        if (window.ws && window.ws.registered) {
          // @ts-ignore
          clearInterval(interval);
          try {
            if (symbol) {
              window?.ws?.send("POST", "/api/room", "COMMON", eventData);
              this.setState(() => ({
                wsRoom: room
              }));
            } else {
              const { wsRoom } = this.state;
              Object.keys(wsRoom).forEach((key: string) => {
                window?.ws?.send("POST", "/api/room", "COMMON", wsRoom[key]);
              });
            }
          } catch (e) {
            Bugsnag.notify(new Error(formatBugsnagMessage(e.toString(), "ws.send(/api/room)")));
          }
        }
      }, 1 * 10);
    };

    convertSymbolInfo: (item) => IMarketPushItemType = (item) => {
      const symboInfo: IMarketPushItemType = {
        coinCode: item.coinCode ?? item.symbol, // 盘口名称
        highPrice: item.highPrice ?? item.high, // 最高价
        lowPrice: item.lowPrice ?? item.low, // 最低价
        volume: item.volume, // 交易量
        quoteVolume: item.quoteVolume, // 交易量
        priceLast: item.priceLast ?? item.mid, // 最新价
        riseAndFall: item.riseAndFall ?? item.percentage, // 涨跌比率
        orderQuoteMax: item.orderQuoteMax,
        orderQuoteMin: item.orderQuoteMin,
        orderBaseMax: item.orderBaseMax,
        orderBaseMin: item.orderBaseMin,
        ask: item.ask, // 卖一价(longPrice /ask)
        bid: item.bid // // 买一价, (shortPirce/bid )
      };
      return symboInfo;
    };
    updateAllSymbolInfoList: (data: IMarketPushItemType[]) => void = (data) => {
      const newSymbols: Record<string, IMarketPushItemType> = {};
      const symbolNames = [];
      data.map((item: IMarketPushItemType) => {
        const _symbolInfo: IMarketPushItemType = this.convertSymbolInfo(item);
        newSymbols[item.coinCode] = _symbolInfo;
        // @ts-ignore
        symbolNames.push(item.coinCode);
      });
      this.props.updateSymbolInfo && this.props.updateSymbolInfo({ symbolInfo: newSymbols });
      this.props.updateSymbolNames && this.props.updateSymbolNames({ symbolNames });
    };

    // 更新离线盘口信息
    updateOfflineSymbolInfoList: (data: IPrecisions[]) => void = (data) => {
      const newOfflineSymbolList = {};
      data.map((item: IPrecisions) => {
        const symbol: IPrecisions = {
          coinCode: item.coinCode, // 盘口名称
          volumePrecision: item.volumePrecision,
          tradeVolumePrecision: item.tradeVolumePrecision, // 成交量实际精度
          symbolPrecision: item.symbolPrecision, // 市场价格展示精度
          tradePrecision: item.tradePrecision, // 价格输入精度
          tradeInputPrecision: item.tradeInputPrecision // 交易币输入精度
        };
        newOfflineSymbolList[item.coinCode] = symbol;
      });
      this.props.updateOfflineSymbolInfo({
        offlineSymbolInfoList: newOfflineSymbolList
      });
    };

    websocketListener: (data: ISymbolDataType) => void = (data) => {
      const now = new Date().getTime();
      if (this.state.componentDidMount && window.ws?.registered) {
        this.setState({
          lastWsPushTime: now
        });
      }
      if (document.visibilityState != "visible") return;
      const currentWsCoinCode = data.data.symbol;
      const currentCoinCode = this.getCurrentCoinCode();

      switch (data.type) {
        case "orderbook":
          if (currentCoinCode == currentWsCoinCode && location.href.includes("trade")) {
            this.updateOb(data.data);
            this.props.updateDepth &&
              this.props.updateDepth({ depthData: this.reOrgDepthData(data.data) });
          }
          break;
        case "market-new":
          Object.assign(newMarketData, {
            [currentWsCoinCode]: data.data
          });
          // 当前选中盘口或者是首页盘口，最小间隔1s， 更新盘口
          if (new Date().getTime() - this.state.marketItemUpdateTs < 1 * 1000) return;
          if (
            currentWsCoinCode == currentCoinCode ||
            ((this.system === BRAND ? HOME_SYMBOLS : ADGM_HOME_SYMBOLS).includes(
              currentWsCoinCode
            ) &&
              location.href.includes(HOME_PATH)) ||
            (STRUCTURED_SYMBOLS.includes(currentWsCoinCode) &&
              location.href.includes(STRUCTURED_PATH.STRUCTURED))
          ) {
            this.setState({
              marketItemUpdateTs: new Date().getTime()
            });
            const _marketSymbolsList: ISymbolInfo = cloneDeep(this.props.marketSymbolsList);
            Object.keys(newMarketData).map((coinCode) => {
              const updateItem = newMarketData[coinCode];
              const _item = this.convertSymbolInfo(updateItem);
              _marketSymbolsList[coinCode] = { ..._marketSymbolsList[coinCode], ..._item };
            });
            this.props.updateSymbolInfo &&
              this.props.updateSymbolInfo({ symbolInfo: _marketSymbolsList });
          } else {
            // ignore
          }
          break;
        case "order":
          if (location.href.includes(TRADE_PATH)) {
            this.props.updateOpenOrder && this.props.updateOpenOrder(data.data);
            console.log(`received ws message order` + `\n${JSON.stringify(data.data)}`);

            /* TODO: 因除了trade和home模块有ws推送，其它页面没有，所以当用户下完单，跳到其他页面时，我们没办法监听到数据变化
            try {
              const marketInfo = localStorage.getItem(MARKET_ORDER_INFO");
              const marketOrderInfo = marketInfo ? JSON.parse(marketInfo) : [];
              const marketOrderIds = marketOrderInfo.map((item) => item.orderId);

              if (marketOrderIds.includes(data.data.orderId)) {
                const updateMarketOrder = marketOrderInfo.filter((order) => {
                  // 已成交的订单 删除存储的订单信息；
                  if (order.orderId === data.data.orderId && data.data.orderStatus === "filled") {
                    return false;
                  }

                  // 未完全成交的订单，更新时间和状态
                  order.startTime = new Date().getTime();
                  order.orderStatus = data.data.orderStatus;

                  return true;
                });

                // 更新市价订单信息
                localStorage.setItem(MARKET_ORDER_INFO", updateMarketOrder);
              }
            } catch (e) {
              console.log(e.toString());
            } */
          }
          break;
        default:
          break;
      }
    };

    unregisterWS: () => void = () => {
      // todo, unregister 程序不报错，ws仍可以收到推送消息, @elaine
      this.setState({
        lockConnect: false
      });
      if (window.wstimer) {
        // @ts-ignore
        clearInterval(window.wstimer);
      }
      if (window.reconnectTimer) {
        // @ts-ignore
        clearInterval(window.reconnectTimer);
      }
      if (window?.ws) {
        try {
          window.ws.autoConnect = false;
          window?.ws?.ws?.close();
          window.ws.unregister();
          window.ws = null;
          console.log("unregister ws, ws is closed");
        } catch (e) {
          // Bugsnag.notify(new Error(formatBugsnagMessage(e.toString(), "ws.unregister")));
        }
      }
    };

    registerWS: () => void = async () => {
      window.ws = new WS({
        url: websocket.host,
        authType: "appCode",
        appCode: websocket.appCode,
        // @ts-ignore
        stage: websocket.stage,
        registerPath: websocket.registerPath,
        unregisterPath: websocket.unregisterPath
      });
      if (!this.state.lockConnect) {
        if (!window.ws?.registered) {
          window.ws = new WS({
            url: websocket.host,
            authType: "appCode",
            appCode: websocket.appCode,
            // @ts-ignore
            stage: websocket.stage,
            registerPath: websocket.registerPath,
            unregisterPath: websocket.unregisterPath
          });
        }
        console.log("register ws");
        const deviceId = createUUID();
        try {
          const jwtToken = getSessionStorageTokenKey(location.origin);
          const agentOperatingToken = getSessionStorageTokenKey(location.origin, AGENT);
          const token =
            sessionStorage.getItem(agentOperatingToken) ?? sessionStorage.getItem(jwtToken);
          window.ws.register(this.websocketListener, deviceId, {
            room: "marketData",
            // 传入userData， 如果有对应的委托单推送，则自动进行推送， 不需要推送订单可不传
            value: JSON.stringify({
              jwtToken: token ?? ""
            })
          });
          this.setState({
            lockConnect: true
          });
        } catch (e) {
          // Bugsnag.notify(new Error(formatBugsnagMessage(e.toString(), "ws.register")));
        }
        // 心跳检测
        window.wstimer = setInterval(() => {
          try {
            window?.ws?.send("POST", "/api/alive", "COMMON");
          } catch (e) {
            // Bugsnag.notify(new Error(formatBugsnagMessage(e.toString(), "ws.send(/api/alive)")));
          }
        }, 5 * 1000);

        // 60s 轮询一次，如果检查超过1分钟没有收到websokcet消息，进行重试
        window.reconnectTimer = setInterval(() => {
          if (!this.state.componentDidMount) return;
          const now = new Date().getTime();
          const { lastWsPushTime, coinCode } = this.state;
          if ((lastWsPushTime > 0 && now - lastWsPushTime > 60 * 1000) || !lastWsPushTime) {
            console.log("ws closed: 超过1分钟没有收到websokcet消息");
            if (window.ws) {
              try {
                this.unregisterWS();
              } catch (e) {
                // Bugsnag.notify(new Error(formatBugsnagMessage(e.toString(), "ws.reconnect")));
              }
            }
            console.log("ws closed，will try to reconnect");
            this.registerWS();
            this.sendOrderBookWsData(coinCode);
          }
        }, 60 * 1000);
      }
    };

    shouldComponentUpdate() {
      return false;
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            updateDepth={this.props.updateDepth}
            updateSymbolInfo={this.props.updateSymbolInfo}
            updateSymbolNames={this.props.updateSymbolNames}
            updateOpenOrder={this.props.updateOpenOrder}
            registerWS={this.registerWS}
            unregisterWS={this.unregisterWS}
            updateAllSymbolInfoList={this.updateAllSymbolInfoList}
            updateCurrentOrderBook={this.updateCurrentOrderBook}
            sendOrderBookWsData={this.sendOrderBookWsData}
          />
        </>
      );
    }

    componentDidMount() {
      const { needSymbols, needWebsocket } = defaultProps ?? {
        needSymbols: true,
        needWebsocket: false
      };
      this.setState({
        componentDidMount: true
      });
      const pathname = getCurrentPathName(this.props.router.pathname.toString());
      if (needSymbols) {
        this.fecthSymboInfoList();
        this.fetchOfflineSymbolInfoList();
      }
      if (!this.state.lockConnect && needWebsocket) {
        this.registerWS();
      }
    }

    componentWillUnmount() {
      this.unregisterWS();
      // @ts-ignore
      this.setState({
        componentDidMount: false
      });
      // @ts-ignore
      this.setState = () => false;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps())(withRouter(WithFiatAndSpotTrade));
};
