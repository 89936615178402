import React from "react";
import styles from "./AsPagination.module.less";
import { Button } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";
import type { IPagerInfo } from "@aspen/model/index";

interface IProps {
  hiddenPage?: boolean;
  pagerInfo: IPagerInfo;
  onPageChange: (pagerInfo: IPagerInfo) => void;
}

interface IState {
  tokenList: { [key: string]: string };
}

class AsPagination extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tokenList: {}
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState): Partial<IState> {
    const {
      pagerInfo: { page, nextToken }
    } = props || {};
    const { tokenList } = state;
    const _tokenList = { ...tokenList };
    //当前页面,其实获取的是下一页的token
    _tokenList[page + 1] = nextToken;
    return {
      tokenList: _tokenList
    };
  }

  firstPage = () => {
    this.pageChange(1);
  };

  prePage = () => {
    const {
      pagerInfo: { page }
    } = this.props;
    const _page = page <= 2 ? 1 : page - 1;
    this.pageChange(_page);
  };
  nextPage = () => {
    const {
      pagerInfo: { page }
    } = this.props;
    this.pageChange(page + 1);
  };

  pageChange = (page: number) => {
    const { onPageChange } = this.props;
    const { tokenList } = this.state;
    const nextToken = tokenList[page];
    onPageChange({ page, nextToken });
  };

  render() {
    const { tokenList } = this.state;
    const {
      pagerInfo: { page },
      hiddenPage = false
    } = this.props;
    const firstEnable = page > 1;
    const previewEnable = page == 2 || tokenList[page - 1];
    const nextEnable = tokenList[page + 1];

    if (hiddenPage) {
      return null;
    }
    return (
      <div className={styles.content}>
        <Button
          icon={<DoubleLeftOutlined />}
          onClick={this.firstPage}
          disabled={!firstEnable}
          type="link"
          className={styles.btn}
        />
        <Button
          icon={<LeftOutlined />}
          onClick={this.prePage}
          disabled={!previewEnable}
          type="link"
          className={styles.btn}
        />
        <div className={styles.number}>{page}</div>
        <Button
          icon={<RightOutlined />}
          onClick={this.nextPage}
          disabled={!nextEnable}
          type="link"
          className={styles.btn}
        />
        <Button icon={<DoubleRightOutlined />} disabled type="link" className={styles.btn} />
      </div>
    );
  }
}
export { AsPagination };
