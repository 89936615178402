import React from "react";
import type { Plot } from "@ant-design/plots";
import { Pie } from "@ant-design/plots";
import type { PieConfig } from "@ant-design/plots/es/components/pie";
import type { PieOptions } from "@antv/g2plot/esm/plots/pie/types";
import { isEqual } from "lodash-es";
import {
  BENCHMARK_CURRENCY_SYMBOL,
  convertUSD2USDC,
  decimalPointNoMoreX,
  numberToThousands,
  systemDetection
} from "@aspen/libs";
import type { IOriginalItems } from "@aspen/model";
import { ADGM_BRAND } from "@aspen/model";
import variables from "@aspen/theme/variables.module.less";

export interface IAssetsAllocationModel {
  strategyQuantity: number;
  spotQuantity: number;
  totalUsdAmount: number;
  yieldQuantity: number;
  structuredAmount: number; // 结构化账户持仓数量
  ventureQuantity: number; // 风投账户持仓数量
  coldWalletQuantity: number; //冷钱包
  fundQuantity?: number;
  currency: string;
  totalQuantity: number;
  percent?: string;
  color?: string;
}

export interface IAssetsAllocationName {
  spot: string;
  yield?: string;
  structured: string;
  strategies: string;
  ventures: string;
  vault?: string;
}

interface IProps {
  initCurrency?: string;
  color?: Array<string>;
  height?: number;
  width?: number;
  allocationName: IAssetsAllocationName;
  onSelected?: (currency: string) => void;
  radius?: number; //饼图所占的比例
  list: Array<IAssetsAllocationModel>;
  hideLabel?: boolean; //隐藏label
  hideTooltip?: boolean; //隐藏悬浮框
  hideLegend?: boolean; //隐藏图例
  showPercent?: boolean; //显示图例中的百分比
  otherText?: string;
  notRemoveInteraction?: boolean; //是否不禁用图例点击事件 （默认禁用）
}

interface IState {}

class AssetsAllocationChart extends React.Component<IProps, IState> {
  plot: Plot<PieOptions> | undefined | null;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  /**
   * 根据货币符号查询数据
   */
  _getItemByName = (name) => {
    const { list } = this.props;
    return list.filter((item) => {
      return item.currency == name;
    })[0];
  };

  /**
   * 饼图选项发生变化
   */

  pieStateChange = (currency) => {
    let stateObjects = this.plot?.getStates().filter((item) => item.state == "selected") || [];
    let preCurrency = null;
    if (stateObjects[0]) {
      // @ts-ignore
      preCurrency = stateObjects[0]?.data?.currency;
    }
    //清理所有选项的状态
    if (currency != preCurrency) {
      this.plot?.setState("selected", () => true, false);
      // @ts-ignore
      this.plot?.setState("selected", (item) => item?.currency == currency, true);
    }
  };

  handlePieCustomItems = (originalItems: IOriginalItems[]) => {
    const list = originalItems.map((item: IOriginalItems) => {
      const data = item.data;
      let name = "";
      let value = `<div>${numberToThousands(decimalPointNoMoreX(item.value))}</div>`;
      switch (item.name) {
        case "totalQuantity":
          name = convertUSD2USDC(data.currency);
          value =
            data.currency === "Other"
              ? `<div style="text-align: end">${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(
                  decimalPointNoMoreX(data.totalUsdAmount, 2)
                )}</div>`
              : `<div style="text-align: end"><div >${numberToThousands(
                  decimalPointNoMoreX(data.totalQuantity)
                )}</div><div style="margin-top: 5px">${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(
                  decimalPointNoMoreX(data.totalUsdAmount, 2)
                )}</div></div>`;
          break;
        case "spotQuantity":
          name = this.props?.allocationName?.spot;
          break;
        case "yieldQuantity":
          name = this.props?.allocationName?.yield || "";
          break;
        case "structuredAmount":
          name = this.props?.allocationName?.structured;
          break;
        case "strategyQuantity":
        case "fundQuantity":
          name = this.props?.allocationName?.strategies;
          break;
        case "ventureQuantity":
          name = this.props?.allocationName?.ventures;
          break;
        case "coldWalletQuantity":
          name = this.props?.allocationName?.vault || "";
          break;
      }
      return {
        ...item,
        name,
        value
      };
    });
    return list;
  };

  handlePieCustomFields = () => {
    const system = systemDetection();
    let fields = [
      "totalQuantity",
      "spotQuantity",
      "yieldQuantity",
      "structuredAmount",
      "strategyQuantity",
      "ventureQuantity",
      "coldWalletQuantity"
    ];
    if (system === ADGM_BRAND) {
      fields = [
        "totalQuantity",
        "spotQuantity",
        "structuredAmount",
        "fundQuantity",
        "ventureQuantity"
      ];
    }

    return fields;
  };

  render(): React.ReactNode {
    const {
      list,
      hideLabel,
      showPercent,
      hideTooltip,
      hideLegend,
      height,
      width,
      radius,
      color,
      onSelected,
      initCurrency
    } = this.props;

    const config: PieConfig = {
      data: list,
      height,
      width,
      autoFit: !(height || width),
      colorField: "currency",
      angleField: "totalUsdAmount",
      color,
      radius: radius || 0.75,
      innerRadius: 140 / 216,
      pieStyle: { lineWidth: 0, lineOpacity: 0, cursor: "pointer" },
      theme: {
        fontFamily: "Common-Regular"
      },
      statistic: {
        title: undefined,
        content: undefined
      },
      // @ts-ignore
      tooltip: hideTooltip
        ? false
        : {
            // @ts-ignore
            showTitle: false,
            enterable: true,
            domStyles: {
              "g2-tooltip": {
                backgroundColor: variables.tooltipBg,
                boxShadow: "none",
                fontFamily: "Common-Regular"
              },
              "g2-tooltip-list-item": {
                color: variables.white,
                display: "flex",
                alignItems: "baseline",
                minWidth: "170px"
              },
              "g2-tooltip-value": { whiteSpace: "pre-line", marginLeft: "auto" }
            },
            fields: this.handlePieCustomFields(),
            customItems: (originalItems: IOriginalItems[]) => {
              return this.handlePieCustomItems(originalItems);
            }
          },
      label: hideLabel
        ? false
        : {
            type: "spider",
            labelHeight: 45,
            content: "{name}\n{percentage}",
            style: {
              fill: "rgba(255, 255, 255, 0.65)",
              cursor: "pointer",
              fontSize: 12.8,
              lineHeight: 16.5,
              fontFamily: "Common-Regular"
            }
          },
      legend: hideLegend
        ? false
        : {
            layout: "vertical",
            position: "right",
            itemName: {
              formatter: (text) => convertUSD2USDC(text),
              style: () => {
                return {
                  fill: "rgba(255, 255, 255, 0.65)",
                  fontSize: 12.8,
                  lineHeight: 16.5,
                  fontFamily: "Common-Regular"
                };
              }
            },
            itemValue: showPercent
              ? {
                  formatter: (text) => {
                    return this._getItemByName(text).percent;
                  },
                  style: () => {
                    return {
                      fill: "rgba(255, 255, 255, 0.65)",
                      fontSize: 12.8,
                      lineHeight: 16.5
                    };
                  }
                }
              : undefined
          },
      // 添加 中心统计文本 交互
      interactions: []
    };

    return (
      <Pie
        {...config}
        onReady={(plot) => {
          this.plot = plot;
          //移除图例默认事件
          if (!this.props.notRemoveInteraction) {
            this.plot.chart.removeInteraction("legend-filter");
          }
          this.pieStateChange(initCurrency);
          this.plot.chart.on("element:click", (ev) => {
            this.pieStateChange(ev?.data?.data?.currency);
            onSelected && onSelected(ev?.data?.data?.currency);
          });
        }}
      />
    );
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    return !isEqual(nextProps.list, this.props.list);
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

export { AssetsAllocationChart };
