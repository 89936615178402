import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ICustomerInfoModel } from "@aspen/model/index";

interface IUser {
  kycInfo: object;
}

export interface userState {
  kycInfo: object;
  userInfo: ICustomerInfoModel | null;
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const currentUserInfo = (state: RootState) => state.user.kycInfo;

const initialState: userState = {
  kycInfo: {},
  userInfo: null
};
// TODO
export const userSlice: any = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.kycInfo = action.payload.kycInfo;
    },
    updateUserInfo: (state, action: PayloadAction<ICustomerInfoModel>) => {
      state.userInfo = action.payload;
    }
  }
});

export const { updateUser, updateUserInfo } = userSlice.actions;
// exports a reducer function for the counter logic.
export default userSlice.reducer;
