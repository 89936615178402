import React, { useMemo } from "react";
import { Select } from "antd";
import {
  CountriesForPhone,
  DEFAULT_ADGM_COUNTRY,
  DEFAULT_COUNTRY,
  ZH_CN,
  getCurrentLanguage,
  systemDetection
} from "@aspen/libs";
import styles from "./CountrySelect.module.less";
import clsx from "clsx";
import { ADGM_BRAND } from "@aspen/model";

type IProps = {
  onChange: (params) => void;
  defaultValue?: string;
  countrySelectStyle?: string;
};

const CountrySelect: React.FC<IProps> = (props: IProps) => {
  const { onChange, defaultValue, countrySelectStyle } = props;
  const system = systemDetection();

  const initValue = useMemo(() => {
    const item = CountriesForPhone.find((obj) => obj.telephoneCode === defaultValue);
    return item
      ? `${defaultValue}_${item?.id}`
      : system === ADGM_BRAND
        ? DEFAULT_ADGM_COUNTRY
        : DEFAULT_COUNTRY;
  }, [defaultValue]);
  const locale = getCurrentLanguage();
  const formatOptions = (data, locale) => {
    return data?.map((item) => {
      return {
        ...item,
        label: (
          <>
            <span>{item.nationalFlag}</span>
            &nbsp;&nbsp;&nbsp;
            <span className={styles.itemName}>
              {locale === ZH_CN ? item.commonName : item.displayName}
            </span>
            &nbsp;
            <span className={styles.itemCode}>{item.telephoneCode}</span>
          </>
        ),
        value: `${item.telephoneCode}_${item.id}`,
        name: (
          <>
            <span>{item.nationalFlag}</span>
            &nbsp;&nbsp;&nbsp;
            <span className={styles.itemCode}>{item.telephoneCode}</span>
          </>
        )
      };
    });
  };

  return (
    <Select
      popupClassName={styles.countrySelectPopup}
      defaultValue={initValue}
      optionLabelProp="name"
      style={{ width: 120 }}
      bordered={false}
      options={formatOptions(CountriesForPhone, locale)}
      onChange={onChange}
      className={clsx(styles.countrySelect, countrySelectStyle)}
    />
  );
};

export default React.memo(CountrySelect);
