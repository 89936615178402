"use client";
import React, { memo, useEffect, useRef } from "react";
import { Modal, Spin } from "antd";
import {
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  i18nUtil,
  reportEvent,
  USER,
  USER_AUTH,
  USER_ROLE
} from "@aspen/libs";
import { AccountInput, EmailInputGroup, PhoneInputGroup, SafetyTips } from "@aspen/ui";
import type {
  IBindingBusinessType,
  IBusinessVerificationHeaderModel,
  IRes,
  IVerificationType
} from "@aspen/model";
import { getCustomerConfigInfo } from "@aspen/services";
import { ModalBusinessSwitchVerify } from "../modalBusinessSwitchVerify";
import styles from "@aspen/theme/Clients.module.less";

interface IProps {
  confirmLoading: boolean;
  visible: boolean;
  businessType: IBindingBusinessType;
  confirm: (res: IBusinessVerificationHeaderModel) => void;
  cancel: () => void;
  content?: string;
}

export const ModalBusinessVerification = memo((props: IProps) => {
  const intl = i18nUtil.t();
  const defaultVerifyChannels = ["email", "google"]; // 支持安全验证方式的数量大于2时的默认展示
  const { visible, businessType, confirm, cancel, content, confirmLoading } = props;
  const callbackResForBusiness = useRef<IBusinessVerificationHeaderModel>({});
  const [
    {
      loading,
      phoneCode,
      emailCode,
      googleCode,
      isVisible,
      supportVerificationType,
      selectedVerifyChannels
    },
    setConfig
  ] = React.useState<{
    loading: boolean;
    phoneCode: string;
    emailCode: string;
    googleCode: string;
    isVisible: boolean;
    supportVerificationType: IVerificationType[];
    selectedVerifyChannels: IVerificationType[];
  }>({
    loading: false,
    phoneCode: "",
    emailCode: "",
    googleCode: "",
    isVisible: false,
    supportVerificationType: [],
    selectedVerifyChannels: ["email"]
  });

  useEffect(() => {
    getSupportedVerifyType();
  }, []);

  const getSupportedVerifyType = async () => {
    try {
      setLoading(true);
      const res: IRes = await getCustomerConfigInfo();
      setLoading(false);
      const supportValidChannels = res?.data?.supportValidChannels || [];
      setConfig((prevConfig) => ({
        ...prevConfig,
        supportVerificationType: supportValidChannels
      }));
      if (supportValidChannels.length) {
        if (supportValidChannels.length > 2) {
          setConfig((prevConfig) => ({
            ...prevConfig,
            selectedVerifyChannels: defaultVerifyChannels
          }));
        } else {
          setConfig((prevConfig) => ({
            ...prevConfig,
            selectedVerifyChannels: res?.data?.supportValidChannels
          }));
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const setCode = (type, code) => {
    setConfig((prevConfig) => ({ ...prevConfig, [type]: code }));
    if (code === "") {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete callbackResForBusiness.current[type];
    } else {
      callbackResForBusiness.current[type] = code;
    }
  };
  const setLoading = (flag) => {
    setConfig((prevConfig) => ({ ...prevConfig, loading: flag }));
  };
  const setSelectModalShow = (flag) => {
    setConfig((prevConfig) => ({ ...prevConfig, isVisible: flag }));
  };
  const handleOk = () => {
    confirm && confirm(callbackResForBusiness.current);
  };
  const onCancel = () => {
    cancel && cancel();
  };
  const changeMethod = () => {
    setSelectModalShow(true);
  };

  const selectVerificationChanged = (typeList: IVerificationType[]) => {
    if (!typeList.includes("email")) {
      emailCode && setConfig((prevConfig) => ({ ...prevConfig, emailCode: "" }));
      if (callbackResForBusiness.current.emailCode)
        delete callbackResForBusiness.current?.emailCode;
    }
    if (!typeList.includes("phone")) {
      phoneCode && setConfig((prevConfig) => ({ ...prevConfig, phoneCode: "" }));
      if (callbackResForBusiness.current.phoneCode)
        delete callbackResForBusiness.current?.phoneCode;
    }
    if (!typeList.includes("google")) {
      googleCode && setConfig((prevConfig) => ({ ...prevConfig, googleCode: "" }));
      if (callbackResForBusiness.current.googleCode)
        delete callbackResForBusiness.current?.googleCode;
    }
    setConfig((prevConfig) => ({
      ...prevConfig,
      selectedVerifyChannels: typeList as IVerificationType[]
    }));
  };

  //子账号只给自己发邮件
  const renderSub = () => {
    return (
      <EmailInputGroup
        // @ts-ignore
        businessType={businessType}
        email={sessionStorage.getItem(USER)}
        label={intl["login.email.verify.code"]}
        onChangeTxt={(code) => {
          setCode("emailCode", code);
        }}
      />
    );
  };
  const renderContent = () => {
    return (
      <>
        {selectedVerifyChannels.includes("email") ? (
          <div style={{ marginBottom: "16px" }}>
            <EmailInputGroup
              // @ts-ignore
              businessType={businessType}
              label={intl["login.email.verify.code"]}
              onChangeTxt={(code) => {
                setCode("emailCode", code);
              }}
            />
          </div>
        ) : null}
        {selectedVerifyChannels.includes("phone") ? (
          <div style={{ marginBottom: "16px" }}>
            <PhoneInputGroup
              // @ts-ignore
              businessType={businessType}
              label={intl["login.phone.verify.code"]}
              onChangeTxt={(code) => {
                setCode("phoneCode", code);
              }}
            />
          </div>
        ) : null}
        {selectedVerifyChannels.includes("google") ? (
          <div style={{ marginBottom: "16px" }}>
            <AccountInput
              // @ts-ignore
              label={intl["login.google.verify.code"]}
              onChangeTxt={(code) => {
                setCode("googleCode", code);
              }}
            />
          </div>
        ) : null}
        {supportVerificationType.length > 2 ? (
          <div style={{ marginBottom: "24px" }}>
            <span
              className={styles.mainColor}
              style={{ fontSize: "14px" }}
              onClick={() => {
                changeMethod();
              }}>
              {intl["verification.methods.switch"]}
            </span>
          </div>
        ) : null}
      </>
    );
  };
  const user_auth = typeof window != "undefined" && localStorage.getItem(USER_AUTH);
  return (
    <>
      <Modal
        maskClosable={false}
        title={intl["verification.title"]}
        open={visible}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        centered
        okText={intl["button.confirm"]}
        onCancel={onCancel}
        okButtonProps={{
          disabled:
            user_auth === USER_ROLE.ROLE_SUB
              ? !(Object.keys(callbackResForBusiness.current).length === 1)
              : !(
                  Object.keys(callbackResForBusiness.current).length ===
                  selectedVerifyChannels.length
                )
        }}
        width={480}>
        <Spin spinning={loading}>
          {content ? <SafetyTips description={content} /> : null}
          {user_auth === USER_ROLE.ROLE_SUB ? renderSub() : renderContent()}
        </Spin>
      </Modal>
      <ModalBusinessSwitchVerify
        visible={isVisible}
        handleChangeVerifyMethod={() => {}}
        onCancel={() => {
          setSelectModalShow(false);
        }}
        confirm={(typeList) => {
          reportEvent({
            moduleName: GA_EVENT_NAME.common.changeVerifyMethod,
            joinedTag: GA_EVENT_TAG.Click,
            detailParams: {
              method: typeList ? (typeList as IVerificationType[]).join(",") : ""
            }
          });
          selectVerificationChanged(typeList);
          setSelectModalShow(false);
        }}
        supportValidChannel={supportVerificationType}
        currentMode={selectedVerifyChannels}
      />
    </>
  );
});
