/* 模块内容为空时展示的占位符 */
/* todo : 待确认是否在加载时展示缺省，还是加载完成空数据展示缺省*/
import React from "react";
import { ConfigProvider } from "antd";

import { i18nUtil } from "@aspen/libs/index";

const defaultProps = {
  config: {
    height: 400,
    width: "100%",
    minHeight: 400,
    paddingTop: 100
  },
  imgWidth: "auto",
  // 缺省图类型
  type: "table"
};
type IProps = {
  showEmpty: boolean; // 是否展示缺省图和文案
  children: React.ReactNode;
  emptyDesc?: string;
} & Partial<typeof defaultProps>;

class EmptyContent extends React.Component<IProps> {
  static defaultProps = defaultProps;
  constructor(props) {
    super(props);
  }

  // 设置空状态
  customizeRenderEmpty = () => {
    const intl = i18nUtil.t();
    return (
      <div style={{ ...this.props?.config }}>
        <p style={{ marginTop: 40 }}>{this.props.emptyDesc ?? intl["empty.list"]}</p>
      </div>
    );
  };

  render(): React.ReactNode {
    return (
      <>
        {this.props.showEmpty ? (
          <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
            {this.props?.children}
          </ConfigProvider>
        ) : (
          this.props?.children
        )}
      </>
    );
  }
}

export { EmptyContent as WithEmptyContent };
