import type { ISaasCommonCacheKeys } from "@aspen/model";
import { useSaasFecthers } from "../useSaasFecthers";
import { localforageStorage, sessionStorageUtils } from "@aspen/libs";
/**
 * @description 可复用的请求数据，接口地址+参数一致即可复用
 * 只需要指定组件使用什么数据，不需要关心数据的获取和更新，同时可以拿到接口返回数据在自己业务逻辑中使用
 * @param key 唯一key名称
 * 查询键驱动查询： 如果有一些改变你数据的状态，需要做的就是把它放在 Query Key 中，因为每当 key 发生变化时，React Query 都会自动触发重新获取。因此，当你想应用过滤器时，只需更改你的客户端状态，参考：https://juejin.cn/post/7025080184021712933
 *
 const [filters, setFilters] = React.useState()
 const { data } = useQuery(['todos', filters], () => fetchTodos(filters))
 // set local state and let it "drive" the query
 return <Filters onApply={setFilters} />
 */
function useSaasCachePriorityData<T>(options: {
  key: ISaasCommonCacheKeys;
  params?: any;
  useQueryExtraOptions?: any;
}) {
  const { key, params, useQueryExtraOptions } = options;
  if (!key)
    return {
      data: null,
      error: "",
      isLoading: false,
      isValidating: false
    };
  const queryParam = params;
  const queryFn = useSaasFecthers({
    key,
    params: queryParam,
    useQueryExtraOptions
  });
  let {
    data,
    error,
    isLoading,
    isValidating,
    refetch,
    isPlaceholderData,
    queryKey,
    hasNextPage,
    fetchNextPage,
    sessionStorageKey
  } = queryFn && queryFn();

  if (sessionStorageKey) {
    // 查询数据存入和首次非刷新页面的数据存入sessionStorage
    sessionStorageKey &&
      data &&
      sessionStorageUtils.setItem(sessionStorageKey.toString(), JSON.stringify(data));
  } else {
    isPlaceholderData &&
      queryKey &&
      data &&
      localforageStorage.setItem(queryKey.toString(), JSON.stringify(data));
  }

  return {
    data,
    error,
    isLoading,
    isValidating,
    refetch,
    hasNextPage,
    fetchNextPage,
    isPlaceholderData
  };
}

export { useSaasCachePriorityData };
