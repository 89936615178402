import React from "react";
import { useCachePriorityData } from "@aspen/hooks";
import type { IAssetWalletInfo, ICommonCacheKeys } from "@aspen/model/index";

interface IProps {
  cachekey: ICommonCacheKeys; // 和useCachePriorityData里约定一致的, useCachePriorityData会根据key请求不同的接口返回数据
  render?: (data: any) => JSX.Element;
  callback?: (res: any) => void;
}

const CommonChaceData: React.FC<IProps> = (props) => {
  const { cachekey } = props;
  let res;
  if (typeof cachekey == "string") {
    res = useCachePriorityData<IAssetWalletInfo>(cachekey);
  } else {
    return null;
  }
  console.log(`CommonChaceData ${cachekey}`, JSON.stringify(res));
  props.callback && props.callback(res);

  return (
    <>
      {/* 使用 render prop 动态决定要渲染的内容, class组件使用 */}
      {props.render ? props.render(res) : null}
    </>
  );
};

export { CommonChaceData };
