import { useContext } from "react";
import { OperationContext } from "@aspen/store";
import { isClient, OPERATION_TOKEN } from "@aspen/libs";

export const useOperationContextParams = () => {
  const { token, isOperation = false, employeeIds = [] } = useContext(OperationContext) ?? {};
  const tokenStorage = isClient && window.localStorage?.getItem(OPERATION_TOKEN);
  return {
    isOperation,
    token: token || tokenStorage,
    employeeIds: employeeIds.join(",")
  };
};
