import React from "react";
import type { InputProps, InputRef } from "antd";
import { Input as AntInput } from "antd";

// 去除首尾空格
const withTrim = (WrappedComponent) => {
  return (props: InputProps & React.RefAttributes<InputRef>) => {
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
      e.target.value = e.target.value.trim();

      const { onChange, onBlur } = props;

      if (typeof onChange === "function") {
        onChange?.(e);
      }

      if (typeof onBlur === "function") {
        onBlur?.(e);
      }
    };

    return <WrappedComponent {...props} onBlur={handleBlur} />;
  };
};
const WithTrimInput = withTrim(AntInput);
export { WithTrimInput };
