import React from "react";
import { Liquid } from "@ant-design/plots";
import { LiquidConfig } from "@ant-design/plots/es/components/liquid";
import variables from "@aspen/theme/variables.module.less";

interface IProps {
  themeColor: string;
  content?: string;
  width?: number;
  height?: number;
}

interface IState {}

class PNLiquidChart extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const { themeColor, content, width, height } = this.props;
    const config: LiquidConfig = {
      width: width,
      height: height,
      autoFit: !(!!width || !!height),
      percent: 0.2,
      radius: 1,
      shape: "circle",
      liquidStyle: {
        fill: themeColor,
        stroke: themeColor,
        lineWidth: 0.5
      },
      outline: {
        border: 1
      },
      wave: {
        length: 60
      },
      statistic: {
        content: {
          style: {
            fontSize: "14px",
            color: variables.statisticColor,
            fontFamily: "Common-Regular"
          },
          content: content
        }
      }
    };
    return <Liquid {...config} />;
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

export { PNLiquidChart };
