import { DOMAIN_BETA, DOMAIN_DEV, DOMAIN_PROD, DOMAIN_PROD_MANAGER, DOMAIN_PROD_PARTNER } from ".";
import { isClient } from "../../utils";

const originCode = {
  [DOMAIN_PROD]: "GTM-P6B2Q6C",
  [DOMAIN_PROD_PARTNER]: "GTM-P6B2Q6C",
  [DOMAIN_PROD_MANAGER]: "GTM-P6B2Q6C",
  [DOMAIN_DEV]: "GTM-PRP9G9D",
  [DOMAIN_BETA]: "GTM-PRP9G9D"
};

const apikeyGtm = isClient && originCode[location.hostname];
export default apikeyGtm ?? "";
