"use client";
import React from "react";
import styles from "./ModalKycGuide.module.less";
import { Modal, Button } from "antd";
import { i18nUtil, PROFILE_PATHS, USER_AUTH, USER_ROLE_POWER } from "@aspen/libs/index";
import { withRouter } from "next/router";
import type { IInjectProps } from "@aspen/model/index";

interface IProps extends IInjectProps {
  visible: boolean;
  noClose: boolean;
  handleCancel: () => void;
  // 传参是否展示跳转profile页面的按钮，不传则默认展示
  showToProfileBtn: boolean;
}

class ModalKycGuide extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  handleClickOnKyc = () => {
    const { showToProfileBtn = true } = this.props;
    this.props.handleCancel();
    if (showToProfileBtn) return;
    const query = `?anchorPoint=kyc`;
    this.props.router.push(`${PROFILE_PATHS.PROFILE}${query}`);
  };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { showToProfileBtn = true } = this.props;
    return (
      <>
        <Modal
          wrapClassName={styles.modal}
          title={intl["home.kyc.title"]}
          open={this.props.visible}
          centered
          footer={null}
          destroyOnClose
          width={480}
          maskClosable={!this.props.noClose}
          closable={!this.props.noClose}
          onCancel={() => this.props.handleCancel()}>
          <div className={styles.actions}>
            {/* 不区分具体状态，统一文案和交互 */}
            <p className={styles.content}>{intl["home.kyc.content"]}</p>
            <Button type="primary" onClick={() => this.handleClickOnKyc()}>
              {showToProfileBtn ? intl["button.ok"] : intl["home.kyc.action"]}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(ModalKycGuide);
