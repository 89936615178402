// localStorage keys
export const LANGUAGE = "language";
export const SHOW_RECAPTCHA = "showRecaptcha";
export const IS2FA = "is2fa";
export const FORMATWEBP = "formatwebp";
export const DEVICE_ID = "deviceId";
export const USER_AUTH = "user_auth";
export const JWT_TOKEN = "jwtToken";
export const AGENT_OPERATING_TOKEN = "agentOperatingToken";
export const SKIP_CHANGE_PASSWORD = "skipChangePassword";

export const USER = "user";
export const USER_COUNTRY_CODE = "countryCode";
export const USER_NATIONAL_NUMBER = "nationalNumber";
export const EMAIL = "email";
export const CUSTOMER_ID = "customerId";
export const CUSTOMER_INFO = "customerInfo";
export const OPERATE_CUSTOMER_EMAIL = "operateCustomerEmail";
export const OPERATE_CUSTOMER_ID = "operateCustomerId";
export const ERROR = "error";
export const MEMBER_INFO = "member_info"; // 会员信息

export const KYC_CERTIFIED_LEVEL = "kycCertifiedLevel";
export const KYC_CERTIFIED_STATUS = "kycCertifiedStatus";

export const IS_GOOGLE_Certified = "isGoogleCertified";
export const IS_AUTHENTICATED = "isAuthenticated";
export const AUTHORIZATION_TYPE = "authorizationType";

export const ASSETANALYSIS = "assetAnalysis";

export const COIN_CODE = "coinCode";
export const MARKET_ORDER_INFO = "marketOrderInfo";

export const REFILL_BANK_INFO = "refillBankInfo";

export const LOGIN_REDIRECT = "loginRedirect";

export const REDIRECT_ID = "redirectId";

export const KYC_DATA_CACHE = "kycDataCache";

export const OPERATION_TOKEN = "operation-token";

export const KEY_COMPLIANCE_TAG = "key_compliance_tag_cache_";

// localStorage需要保留的keys
export const RESERVED_KEYS = [
  SHOW_RECAPTCHA,
  IS2FA,
  LANGUAGE,
  FORMATWEBP,
  ASSETANALYSIS,
  LOGIN_REDIRECT,
  REDIRECT_ID,
  OPERATION_TOKEN
];
