import React, { Component } from "react";
import styles from "./Login.module.less";
import Image from "next/image";
import logo from "@aspen/assets/images/aspen_logo_black@2x.svg";
import { handleBlur, handleFocus, i18nUtil, REG_EXP_PASSWORD, systemDetection } from "@aspen/libs";
import LanguageSwitch from "./LanguageSwitch";
import clsx from "clsx";
import DownloadAppQrCode from "./DownloadAppQrCode";
import { BRAND } from "@aspen/model";

interface IProps {}

interface IState {}

export const LoginLayoutPortal = (WrappedComponent) => {
  class LoginPortal extends Component<IProps, IState> {
    system = systemDetection();
    constructor(props: IProps) {
      super(props);

      this.state = {};
    }

    render() {
      const intl = i18nUtil.t();
      return (
        // <Spin
        //   renderLoading={<Loading path="/animations/loading.json" />}
        //   status={this.state.status}>
        // <section className={clsx(styles.login, "login", formatwebp ? "png" : "123")}>
        <section className={clsx(styles.login, "login")}>
          <LanguageSwitch />
          <div className={styles.main}>
            <div className={styles.mainContainer}>
              {this.system === BRAND ? <DownloadAppQrCode /> : null}
              <section className={styles.leftBrief}>
                <div className={styles.logo}>
                  <Image unoptimized src={logo} alt="" />
                </div>
                <p className={styles.title}>{intl["login.title"]}</p>
              </section>
              <WrappedComponent
                {...this.props}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                // updateStatus={this.updateStatus}
              />
            </div>
          </div>
        </section>
        // </Spin>
      );
    }

    componentDidMount(): void {}
  }

  return LoginPortal;
};
