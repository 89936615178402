import type { Dayjs } from "dayjs";
import type { ACCOUNT_TYPE, CMS_PRODUCT_TYPE, CMS_SORT_TYPE, IUserEventEnums } from "./enums";

export type IPropsStep = 1 | 2 | 3;

export interface IkycInfo {
  id?: string;
  customerId?: string;
  type?: 0 | 1;
  userId?: string;
  createTime?: number;
  modifyTime?: number;
  level?: EKycLevel; // 分别对应KYC等级 0 1 2
  status?: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0:未进行kyc，1:kyc1审核中，2:kyc1已拒绝，3:kyc1已通过，4:kyc2审核中，5:kyc2已拒绝，6:kyc2已通过
  age?: string;
  gender?: string;
  birthday?: string | Dayjs;
  annualIncome?: string;
  sourceOfIncome?: string;
  passportId?: string;
  businessNature?: string;
  brNumber?: string;
  companyType?: string;
  representativeName?: string;
  representativeTitle?: string;
  name?: string;
  region?: string;
  country?: string;
  countryCode?: string;
  nationality?: string;
  city?: string;
  street?: string;
  postal?: string;
  contactAddress?: string;
  phoneAreaCode?: string; // 区号字段 前端使用 目前后端无用
  phone?: string;
  email?: string;
  sourceOfFund?: string;
  liquidAssets?: string;
  totalAssets?: string;
  fileUrl?: string;
  remark?: string;
  companyRepresentativeGender?: string;
  companyRepresentativeBirthDate?: string;
  companyRepresentativeNationality?: string;
  companyRegistrationCountry?: string;
}

/* -------------------- 会员相关 -------------------- */
// 会员等级/权益
export interface IMemberEquity {
  created: number;
  equityId: number;
  hyperlink: null;
  icon: null;
  level: string;
  memberVisible: boolean;
  modified: null;
  nameEn: null;
  nameHk: null;
  nameI18n: null;
  nameZh: null;
  namespace: null;
  params: string;
  propsVisible: boolean;
  remarkEn: null;
  remarkHk: null;
  remarkI18n: null;
  remarkZh: null;
  saasId: null;
  scope: null;
  status: null;
  type: null;
}

export interface IMemberEquities {
  level: string;
  currency: string;
  rate: string;
}

// 会员AUM
export interface IReqMemberAum {
  clacDats: number;
}
/* -------------------- end 会员相关-------------------- */

/* -------------------- 代理商历相关 -------------------- */
export interface IReqClientInfo {
  customerId: string;
}
export interface IReqCustomerList {
  customerId?: string;
  limit: number;
  nextToken: string | null;
}
export interface IResClientInfo {
  agentName?: string; //代理商名字
  aumAmount: number | null; //aum金额
  commissionRate?: number; //佣金比率
  updateTime?: number; //金额更新时间
  inviteCode: string; //邀请码
  type: string; //用户类型
}
export interface IResClientAssets {
  agentId?: string;
  date?: number | null;
  settledAmount: number | null;
  totalAmount: number | null;
  unsettledAmount: number | null;
}
export interface IResClientApply {
  apiCategory: string;
  customerId: string;
  effectiveDate: number;
  expirationDate: number;
  authorizeUrl: string;
}
export interface IClientCustomerInfo {
  userName: string;
  id: string;
  email: string;
  type: string;
  isKyced: string;
  kycLevel: string;
  totalAmount: number;
  updateTime: number;
  roleApplyStatus: number;
  deposited: boolean;
  registration: number;
  expirationDate?: number;
}
export interface ICustomerInfoModel {
  countryCode: number;
  deposited: boolean;
  email: string;
  id: string;
  isEmailCertified: true;
  isPhoneCertified: true;
  isGoogleCertified: true;
  nationalNumber: number;
  phone: string;
  updateTime: number;
  userName: string;
  vipEndTime: number;
  vipLevel: string;
}
export interface IPartnerInfo {
  id: string;
  customerId: string;
  relationId: string;
  agentName: string;
  agentEmail: string;
  applicantType: string;
  apiCategory: string;
  effectiveDate: number;
  expirationDate: number;
  status: number;
  reason?: string;
  filePath?: string;
  authorizationType: number;
  saasId: string;
  created: number;
  modified: number;
}
export interface ISubInfo {
  id: string;
  customerId: string;
  email: string;
  status: number;
  apiCategory: string;
  authorizationType: number;
  saasId: string;
  created: number;
  modified: number;
}
//终止代理商
export interface IReqStopPartnerRole {
  businessType: string | number;
  id: string;
  verifyCode: string | number;
  verifyType: string | number;
}
//添加子账号
export interface IReqAddSub {
  subEmail: string;
  subRegisterUrl: string;
}
// 子账号注册
export interface IReqRegisterSub {
  subEmail: string;
  customerId: string;
  subPassword: string;
  apiCategory: string;
  verifyCode: string;
  clientToken?: string;
}
//添加子账号
export interface IReqStopSub {
  businessType: string | number;
  id: string;
  verifyCode: string | number;
  verifyType: string | number;
}

//代理商的客户aum数据查询参数
export interface IReqAum {
  statisticsType: string;
  customerId?: string;
}
//代理商的客户最近活动数据参数
export interface IReqClientRecent {
  customerId?: string;
  businessType?: string;
  nextToken?: string;
  limit?: number;
}
//代理商的客户最近活动数据参数
export interface IReqClientAssetSummary {
  userName?: string;
  email?: string;
  sortType: CMS_SORT_TYPE;
  kycLevel?: string;
  appLogin?: boolean | string;
  limit?: number;
  nextToken?: string;
}

//代理商的客户事件统计信息参数
export interface IReqClientSessionStatistics {
  statisticsType?: string;
}
//代理商客户资产明细
export interface IReqClientCustomerAssets {
  showRevenue?: boolean;
  customerId?: string;
}
//代理商客户资产分布
export interface IReqClientCustomerAssetsAllocation {
  topNumber?: number;
  customerId?: string;
}
//代理商客户资产分布
export interface IReqClientCustomerWalletList {
  customerId: string;
  showPreviousContracts?: boolean;
  limit?: number;
}
//代理商客户活动历史
export interface IReqClientCustomerSessinosHistory {
  customerId: string;
  eventName?: string;
  category?: string;
  subCategory?: string;
  platform?: string;
  limit?: number;
  nextToken?: string;
}
export interface IReqClientProductList {
  productType: CMS_PRODUCT_TYPE;
}

export interface IReqClientSubscriptions {
  productId?: string;
  productType?: CMS_PRODUCT_TYPE;
  email?: string;
  limit: number;
  nextToken: string;
}

export interface IReqClientAumProportion {
  productId?: string;
  productType?: CMS_PRODUCT_TYPE;
}

export interface IReqClientCustomerAumProportion {
  productId?: string;
  productType?: CMS_PRODUCT_TYPE;
  isHoldStaking?: string;
  isHoldCurrStaking?: string;
  isHoldVenture?: string;
  isHoldCurrVenture?: string;
  sortType: string;
  limit: number;
  nextToken: string;
}

export interface IReqClientListProductBusiness {
  productType: CMS_PRODUCT_TYPE;
  limit: number;
  nextToken: string;
}

export interface IReqClientRecentVisit {
  productId?: string;
  productType: CMS_PRODUCT_TYPE;
  email?: string;
  limit: number;
  nextToken: string;
}

export interface IReqClientVentureInterested {
  productId?: string;
  productType: CMS_PRODUCT_TYPE;
  email?: string;
  limit: number;
  nextToken: string;
}

export interface IReqClientStakingAum {
  productId?: string;
}

export interface IReqClientStakingMonthlyParams {
  employeeIds?: string;
  productId?: string;
}
/* -------------------- end 代理商相关 -------------------- */

/* -------------------- start 帮客相关 -------------------- */
// 允许帮客操作开关
export interface IReqAllowHelpOperate {
  allowHelpOperate: boolean;
}

export interface IManagerCustomer {
  userName: string;
  id: string;
  email: string;
  updateTime: number;
  deposited: boolean;
}
/* -------------------- end 帮客相关 -------------------- */

/* --------------------用户相关-------------------- */
export interface ILogin {
  username: string;
  password: string;
}

// 用户角色类型
export const USER_ACCOUNT_TYPE = {
  customer: "CUSTOMER",
  partner: "AGENT",
  manager: "MANAGE_ACCOUNT",
  subcustomer: "SUB_ACCOUNT"
};

// 登录验证渠道
export interface IReqLoginChannel {
  accountName: string;
  accountType?: ACCOUNT_TYPE;
}
export interface IUser {
  userId: string;
}

// 注册
export interface IReqRegister {
  firstName: string; //目前只传一个name后台储存
  accountName: string;
  password: string;
  verifyCode: string;
  clientToken?: string | null;
}
//登陆
export interface IReqLogin {
  accountName: string;
  password: string;
  accountType?: ACCOUNT_TYPE;
  clientToken?: string | null;
  key?: string | number;
}
//管理员登陆
export interface IReqManagerLogin {
  account: string;
  password: string;
  clientToken?: string | null;
  key?: string | number;
}

// 忘记密码重置密码（resetToken会在验证邮箱验证码成功后会返回）
export interface IReqForgetResetPassword {
  userName: string;
  newPassword: string;
  emailCode?: string;
  resetToken?: string | null;
  clientToken?: string | null;
}

// 直接修改密码
export interface IReqResetPassword {
  oldPassword: string;
  newPassword: string;
  emailCode: string;
}

// 直接修改密码
export interface IReqChangeEmail {
  email: string;
  verifyCode: string;
  businessType: number;
  verifyToken: string;
}
// 验证邀请码
export interface IReqverifyInvitationCode {
  invitationCode: string;
}
export interface IResverifyInvitationCode {
  code: string;
  msg: string;
  data: object | null;
}
export interface IResRegister {
  code: string;
  msg: string;
  data: object | null;
}
// 发送邮箱验证码
// businessType详见： https://docs.google.com/document/d/1fObfXLhUnUwhqDbqZh7emDi4SKSWUIPHPnl5osCcQTc/edit
export interface IReqSendEmailCode {
  businessType?: number;
  email?: string;
}
// 验证邮箱验证码
export interface IReqVerifyEmailCode {
  businessType: number;
  email: string;
  verifyCode: string;
}
/**
 * VerificationType
 * 邮箱 谷歌 手机
 */
export type IVerificationType = "email" | "google" | "phone" | "unrequired" | string;
/* --------------------end 用户相关-------------------- */

/* --------------------个人中心相关-------------------- */

export enum typeList {
  Institutions, // 0 机构
  Individuals // 1 个人
}

// 绑定手机
export interface IReqBindPhone {
  phoneCode: string;
  phone: string;
  smsType: 0 | 1; // 0-短信验证码 1-语音验证码
}

// 换绑手机
export interface IReqChangePhone {
  oldPhone: string;
  oldPhoneCode: string;
  newPhone: string;
  newPhoneCode: string;
}

// 解绑手机
export interface IReqUnbindPhone {
  phoneCode: string;
  smsType: 0 | 1; // 0-短信验证码 1-语音验证码
}

/**
 * 获取手机短信验证码  无需登录 （目前只有2fa验证时才会调用该接口，就是手机号登录）
 */
export interface IReqSendPhoneCodeWithoutLogin {
  phone: string;
  businessType: number; // 执行手机号登录操作时，获取手机验证码businessType需传17
  smsType: 0 | 1; // 0-短信验证码 1-语音验证码
}

// 获取手机短信验证码 需要登录
export interface IReqSendPhoneCode {
  phone?: string; // 绑定过手机就不用传该参数
  businessType: number; // 执行绑定手机操作时，获取手机验证码businessType需传13；执行解绑手机操作时，获取手机验证码businessType需传10；执行换绑手机操作时，获取手机验证码businessType需传12
  smsType: 0 | 1; // 0-短信验证码 1-语音验证码
}

// 获取用户是否能做某个事件
export interface IReqUserEventCheck {
  userEventEnum: IUserEventEnums; // 事件名，目前业务只针对ApplyWithdraw做校验，其他传值参考IUserEventEnums
}

// 谷歌解绑
export interface IReqGoogleRemove {
  googleCode: string;
  businessType: number;
}

// 绑定谷歌
export interface IReqGoogleBind {
  googleKey: string;
  googleCode: string;
}

// 获取用户是否能做某个事件
export interface IReqReport {
  reportDate: string; // 日期
  reportUrl: string; // 报表链接
}
export interface IReqReportList {
  limit: number;
  nextToken?: string;
}

export interface IBankList {
  nameAtBank: string;
  bankName: string;
  accountNo: string | number;
  remark: string;
  id: number;
}
// KYC FUNd配置
export interface IQuestionnaireItem {
  label: string;
  name: string;
  options: string[];
  tips?: string[];
  placeholder?: string[];
  scores?: number[];
}
// kyc form object配置
type IItemKeys = "label" | "value";
export type KYCItem = Record<IItemKeys, string | number | any>;
// kyc form公共部分
interface kycCommon {
  name?: KYCItem;
  contactAddress?: KYCItem;
  phoneAreaCode?: KYCItem;
  phone?: KYCItem;
  email?: KYCItem;
  // 公共问卷
  investmentExperience?: KYCItem;
  expectedHoldingPeriod?: KYCItem;
  investmentObjective?: KYCItem;
  lossTolerance?: KYCItem;
  actionDownByPercent20?: KYCItem;
}
//个人kyc from表单参数定义
export interface IIndividualsForm extends kycCommon {
  age?: KYCItem;
  gender?: KYCItem;
  birthday?: KYCItem;
  passportId?: KYCItem;
  nationality?: KYCItem;
  //问卷
  personalAnnualIncome?: KYCItem;
  liquidNetWorth?: KYCItem;
  percentageOfNetWorthInvestment?: KYCItem;
  sourceOfNetWorth?: KYCItem;
  employmentStatus?: KYCItem;
}

//机构from表单参数定义
export interface IInstitutionsForm extends kycCommon {
  businessNature?: KYCItem;
  brNumber?: KYCItem;
  countryCode?: KYCItem;
  contactAddress?: KYCItem;
  companyType?: KYCItem;
  representativeName?: KYCItem;
  representativeTitle?: KYCItem;
  // 问卷
  expectedAnnualTurnover?: KYCItem;
  netAssetValue?: KYCItem;
  percentageOfNAVInvestment?: KYCItem;
  sourceOfNAV?: KYCItem;
  mainBusinessNature?: KYCItem;
}

// kyc等级场景判断类型
export type IKycLevel = 1 | 2;
// kyc类型
export type IKycType = "institutions" | "individuals";

export interface IReqKycCommon {
  type?: number; // institution机构 0, individual个人 1
  status?: number;
  name?: string;
  countryCode?: string;
  nationality?: string;
  contactAddress?: string;
  phoneAreaCode?: string;
  phone?: string;
  email?: string;
  id?: string;
  // 公共问卷
  investmentExperience?: string;
  expectedHoldingPeriod?: string;
  investmentObjective?: string;
  lossTolerance?: string;
  actionDownByPercent20?: string;
  // 新增上传文件与风险分数
  fileUrl?: string[];
  surveyScore?: string;
  sign?: string;
}
// 添加机构kyc
export interface IReqKycInstitutions extends IReqKycCommon {
  businessNature?: string;
  brNumber?: string;
  companyType?: string;
  representativeName?: string;
  representativeTitle?: string;
  companyRegistrationCountry?: string;
  companyRepresentativeGender?: string;
  companyRepresentativeBirthDate?: string;
  companyRepresentativeNationality?: string;
  // 问卷
  expectedAnnualTurnover?: string;
  netAssetValue?: string;
  percentageOfNAVInvestment?: string;
  sourceOfNAV?: string;
  mainBusinessNature?: string;
}
// 添加个人kyc
export interface IReqKycIndividuals extends IReqKycCommon {
  age?: number | string;
  gender?: string;
  birthday?: string;
  //问卷
  personalAnnualIncome?: string;
  liquidNetWorth?: string;
  percentageOfNetWorthInvestment?: string;
  sourceOfNetWorth?: string;
  employmentStatus?: string;
}

export enum EKycLevel {
  Initial = "L0",
  Kyc1 = "L1",
  Kyc2 = "L2"
}

// 获取googlekey
export interface IReqGenerateKey {}

// 验证邮箱是否被注册
export interface IReqEmailIsExist extends IReqKycCommon {
  userName: string;
}
// 普通客户邀请列表
export interface IReqMyInvitation {
  offset: number;
  limit: number;
}
// 审批是否要被代理
export interface IReqAgentApply {
  businessType?: string | number;
  id: string;
  reason?: string;
  status: number; //2:通过 5:拒绝
  verifyCode?: string | number;
  verifyType?: string | number;
}

// 修改秘书账号授权类型
export interface IReqAuthorizationType {
  id: string | number;
  authorizationType: number; //0：单笔授权，1：全权授权
}

export type ILanguageType = "en" | "zh";

/* -------------------- end 个人中心相关 -------------------- */

export interface ICustomerConfigInfo {
  makerFeeRate: number;
  takerFeeRate: number;
  subscribeTopics: string[];
}

export interface IGenerateDataCacheKey {
  params: string;
  api: string;
  language?: ILanguageType;
  customerId?: string;
}
