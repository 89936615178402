import { MARKET_ORDER_INFO } from "../config";

/**
 * @description: // 获取下单时存的订单信息，判断该订单是否成交，成交则删除对应订单信息，未成交的则更新时间和状态
 * @params orderList 订单列表（open order list 和 history order list)
 * @params type open | history
 * @return void
 */
// @ts-ignore
export const judgeFilledMarkerOrder = (orderList, type) => {
  try {
    // 获取下单时存的订单信息
    const marketInfo = localStorage.getItem(MARKET_ORDER_INFO);
    const marketOrderInfo = marketInfo ? JSON.parse(marketInfo) : [];

    // 更新下单的localStorage信息
    // @ts-ignore
    const updateMarketOrderFn = (order) => {
      // @ts-ignore
      const newMarkerOrderInfo = marketOrderInfo.filter((item) => {
        // 已成交的订单 删除存储的订单信息；
        if (order && item.orderId === order.orderId) return false;

        // 未完全成交的订单，更新时间和状态
        item.startTime = order.modifyTime;
        item.orderStatus = order.orderStatus;

        return true;
      });

      // 更新localStorage 市价订单信息
      localStorage.setItem(MARKET_ORDER_INFO, newMarkerOrderInfo);
    };

    // 获取订单列表的orderId数组，方便后面查找localStorage中下单的orderId是否存在
    const marketOrderListIds = orderList
      .filter((item: any) => item.orderType === "market")
      .map((item: any) => item.orderId);

    if (type === "history") {
      // 在 history order 中查找 存在的 下单的orderId, 找到说明订单已成交，则删除存储的订单信息
      const order = marketOrderInfo.find((item: any) => marketOrderListIds.includes(item.orderId));
      updateMarketOrderFn(order);
    }

    if (type === "open") {
      // 在open order中 查找 不存在的 下单的orderId，找到则说明已成交，则删除localStorage存的订单信息 （注意 open order有分页，100条，暂时数据不大于100条，则逻辑没问题； 如果有更多数据，则有漏洞）
      const order = marketOrderInfo.find((item: any) => !marketOrderListIds.includes(item.orderId));
      updateMarketOrderFn(order);
    }
  } catch (e) {
    console.info(e);
  }
};
