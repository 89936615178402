import React from "react";
import type { InputProps } from "antd";
import { REG_EXP_NUMBER, REG_EXP_NUMBER_POSITIVE } from "@aspen/libs";
import { WithTrimInput } from "../withTrimInput";

interface IProps extends InputProps {
  maxLength?: number;
  addonBefore?: string;
  addonAfter?: string;
  onChangeEvent: (value: string | number) => void;
  value?: string | number;
  onBlur?: () => void;
  autoComplete?: string;
  placeholder?: string;
  numberType?: "NUMBER_POSITIVE"; // 暂时只处理了正整数和数值, 默认 "number"
}

class NumericInput extends React.Component<IProps> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => string = (e) => {
    let { value } = e.target;
    const temValue = Number(value);
    let reg = REG_EXP_NUMBER;
    switch (this.props?.numberType) {
      case "NUMBER_POSITIVE":
        reg = REG_EXP_NUMBER_POSITIVE;
    }

    if ((!isNaN(temValue) && reg.test(value)) || value === "") {
      this.props.onChangeEvent(value);
    }
    return "";
  };

  // '.' at the end
  onBlur = () => {
    const { value, onBlur, onChangeEvent } = this.props;
    let valueTemp = value;
    if (value?.toString().charAt(value?.toString().length - 1) === ".") {
      valueTemp = value?.toString().slice(0, -1);
    }
    // @ts-ignore
    onChangeEvent(valueTemp?.toString().replace(/0*(\d+)/, "$1"));
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    return (
      <WithTrimInput
        {...this.props}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onBlur={this.onBlur}
        maxLength={this.props.maxLength ?? 99}
      />
    );
  }
}

export { NumericInput };
