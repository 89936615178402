import { i18nUtil } from "./i18n";

/**
 * DCA确认计划
 * 从DCA所确认的proportion中过滤出支持活期理财的币种
 * @param proportion DCA计划所选币种
 * @param financingProducts 活期产品列表
 */
export const handleFlexibleWithProportion = (proportion: any, financingProducts: any) => {
  let canFinancingCurrency: string[] = [];
  if (proportion.length) {
    for (let i = 0; i < proportion.length; i++) {
      for (let j = 0; j < financingProducts.length; j++) {
        const _currency = proportion[i]?.currency || proportion[i]?.denomination;
        if (_currency === financingProducts[j].currency) {
          canFinancingCurrency.push(_currency);
          break;
        }
      }
    }
  }
  return canFinancingCurrency;
};

/**
 * DCA确认计划
 * 从DCA所确认的proportion中过滤出支持staking的币种
 * @param proportion DCA计划所选币种
 * @param stakingProducts staking产品列表
 */
export const handleStakingWithProportion = (proportion: any, stakingProducts: any) => {
  let canStakingCurrency: string[] = [];
  if (proportion.length) {
    for (let i = 0; i < proportion.length; i++) {
      for (let j = 0; j < stakingProducts.length; j++) {
        const subTime = stakingProducts[j]?.subscribeEndTime - 7 * 24 * 3600 * 1000;
        const subscribeTimeEnable = new Date().getTime() < subTime;
        const _currency: string = proportion[i]?.currency || proportion[i]?.denomination;
        if (_currency === stakingProducts[j].currency && subscribeTimeEnable) {
          canStakingCurrency.push(_currency);
          break;
        }
      }
    }
  }
  return canStakingCurrency;
};

export const handleFlexibleOptionTxt = (proportion: any, canFinancingCurrency: any) => {
  const intl = i18nUtil.t();
  if (canFinancingCurrency.length === 0) {
    return intl["none.automatically.transferred.into.flexible"];
  }
  if (canFinancingCurrency.length !== proportion.length) {
    const txt: string = i18nUtil.formatMessage(
      { id: "part.automatically.transferred.into.flexible" },
      { currency: canFinancingCurrency.join(",") }
    );
    return txt;
  }
};

// @ts-ignore
// TODO  @田世杰
export const handleStakingOptionTxt = (proportion, canStakingCurrency) => {
  const intl = i18nUtil.t();
  if (canStakingCurrency.length === 0) {
    return intl["none.automatically.transferred.into.staking"];
  }
  if (canStakingCurrency.length !== proportion.length) {
    const txt: string = i18nUtil.formatMessage(
      { id: "part.automatically.transferred.into.staking" },
      { currency: canStakingCurrency.join(",") }
    );
    return txt;
  }
};

/**
 * @description: 处理APR
 * @params {
 * value：APR
 * }
 * @return {string}
 */
export const processAPR = (value: any) => {
  let val = parseFloat((Number(value) * 100).toPrecision(12));

  const valStr = val.toString();
  if (val.toString().indexOf(".") > -1) {
    // 存在小数点
    const pos = valStr.indexOf(".");
    return valStr.substring(0, pos + 3);
  }
  return val;
};
