/* eslint-disable no-return-await */
import type { ILanguageType, ISaasCommonCacheKeys } from "@aspen/model";
import {
  EN,
  generateDataCacheKey,
  LANGUAGE,
  localforageStorage,
  SAAS_COMMON_CACHE_PRIORITY_KEYS,
  sessionStorageUtils
} from "@aspen/libs";
import { useQuery } from "@tanstack/react-query";
import { getSaasInterestVentureList, getSaasWalletInfo, saasApi as api } from "@aspen/services";

/**
 * @param key 唯一key名称
 * @param params 请求参数
 * @param useQueryExtraOptions useQuery/useMutation/useInfiniteQuery/useQueryClient等额外参数
 */

const emptyFunction = () => {
  return { isPending: false, error: "", data: undefined, refetch: () => {} };
};

function useSaasFecthers<T>(options: {
  key: ISaasCommonCacheKeys;
  params: any;
  useQueryExtraOptions?: any;
}) {
  const { key, params = {}, useQueryExtraOptions } = options;
  // 这里判断查询enabled是否自动运行，如果没有启用，则不执行, 如果从这里return, 会报错
  // if (
  //   (useQueryExtraOptions &&
  //     Object.keys(useQueryExtraOptions).includes("enabled") &&
  //     useQueryExtraOptions.hasOwnProperty("enabled") &&
  //     !useQueryExtraOptions?.enabled)
  // ) {
  //   return emptyFunction;
  // }

  if (!key) {
    return emptyFunction;
  }

  const generateCacheKey = (api: string, param?: any) => {
    const _language: ILanguageType =
      typeof window !== "undefined"
        ? ((localforageStorage.getItem(LANGUAGE) as ILanguageType) ?? EN)
        : EN;
    return generateDataCacheKey({
      params: JSON.stringify(param),
      api,
      language: _language
    });
  };
  // 获取placeholderData
  const getPlaceholderData = (
    queryKey: string,
    useQueryExtraOptions?: any,
    isSessionStatus?: boolean
  ) => {
    // 注意未开启自动执行时不返回placeholderData的值，避免和一个页面的多个相同请求的placeholderData覆盖
    if (!useQueryExtraOptions?.enabled) return undefined;
    let placeholderData: string | undefined | null = "";
    if (isSessionStatus) {
      const cacheData = sessionStorageUtils.getItem(queryKey.toString());
      placeholderData = cacheData && JSON.parse(cacheData);
    } else {
      const cacheData = localforageStorage.getItem(queryKey.toString());
      placeholderData = cacheData && JSON.parse(cacheData);
    }
    return placeholderData;
  };

  // 获取菜单权限
  const fetcherSaasWalletInfo = () => {
    const apiPath = api.saas.customer.wallet;
    const queryKey = generateCacheKey(apiPath, params);
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);
    const { isLoading, isPending, error, data, isPlaceholderData } = useQuery({
      queryKey: [queryKey],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getSaasWalletInfo(params).then((res) => {
          if (res?.code == "0") {
            return res?.data;
          }
          // 将结果return出去
          return res;
        });
      }
    });
    // @ts-ignore
    data && localforageStorage.setItem(queryKey, JSON.stringify(data));
    return { isLoading, isPending, error, data, queryKey, isPlaceholderData };
  };
  const fetcherSaasInterestVentures = () => {
    const apiPath = api.saas.venture.interestProducts;
    const queryKey = generateCacheKey(apiPath, params);
    let placeholderData = getPlaceholderData(queryKey, useQueryExtraOptions);
    const { isLoading, isPending, error, data, isPlaceholderData } = useQuery({
      queryKey: [queryKey],
      placeholderData,
      ...useQueryExtraOptions,
      queryFn: async () => {
        return await getSaasInterestVentureList(params).then((res) => {
          if (res?.code == "0") {
            return res?.data;
          }
          // 将结果return出去
          return res;
        });
      }
    });
    // @ts-ignore
    const rows = (data?.rows ?? []).map((item: { id: string }) => item.id);
    data && localforageStorage.setItem(queryKey, JSON.stringify(rows));
    return { isLoading, isPending, error, data: rows, queryKey, isPlaceholderData };
  };
  let fetcher;
  switch (key) {
    case SAAS_COMMON_CACHE_PRIORITY_KEYS.saasWalletInfo:
      fetcher = fetcherSaasWalletInfo;
      break;
    case SAAS_COMMON_CACHE_PRIORITY_KEYS.saasInterestVentures:
      fetcher = fetcherSaasInterestVentures;
      break;
    default:
      fetcher = emptyFunction;
  }
  return fetcher;
}

export { useSaasFecthers };
