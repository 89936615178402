"use client";
import { LOGIN_REDIRECT, REDIRECT_ID } from "@aspen/libs";
import type {
  EmailCodeBaseProps,
  ICoinListPropsO,
  IFiatSpotPropsFromRedux,
  IPropsWithFiatAndSpotTrade
} from "@aspen/ui";
import { WithCoinList, WithFiatAndSpotTrade } from "@aspen/ui";
import React, { useEffect } from "react";
import type { IInjectProps } from "@aspen/model";
import { LoadAwscJS } from "./LoadAwsc";
import Router from "next/router";

/**
 * portal/services/parnter 公用的登录基础组件，
 * 主动操作：
 * 1. 获取币种图标等信息
 * 2. 取消订阅ws
 * 3. 获取盘口信息(上架+下架)
 * 4. 加载awsc-js及初始化操作
 * 对外暴露：
 * 默认的登录后跳转及相关方法：handlerAfterSuccessfulLogin
 *
 */

export interface IPropsLoginBase
  extends IInjectProps,
    EmailCodeBaseProps,
    IFiatSpotPropsFromRedux,
    IPropsWithFiatAndSpotTrade,
    ICoinListPropsO {
  children: React.ReactNode;
}

const BaseHandle = (props: IPropsLoginBase) => {
  useEffect(() => {
    const { fetchAllCoinList, unregisterWS } = props;
    fetchAllCoinList && fetchAllCoinList();
    unregisterWS && unregisterWS();
    window.ws = null;
  }, []);

  return (
    <>
      {props?.children}
      <LoadAwscJS />
    </>
  );
};

// 登录之后重定向， @赵靖 check 20231221 check
// any
export const handlerAfterSuccessfulLogin = (resData: any) => {
  const { user_id } = resData;
  let toPath = "/";
  // 用户被踢出或者未登录输入地址栏链接跳回login页面，登录后跳转到之前的路由
  const lastPath = localStorage.getItem(LOGIN_REDIRECT);
  const lastId = localStorage.getItem(REDIRECT_ID);
  //1:用户从未登录，跳转链接跳出后不存在lastId ；2: 用户登录后超时被踢出，存在lastId，当前登录的账号与上次登录的账号一致时，跳转到踢出时的页面
  if (lastPath && lastPath.startsWith("/") && (!lastId || user_id === lastId)) {
    toPath = lastPath.replace("/zh", "");
  }

  Router.push(toPath);
  setTimeout(() => {
    localStorage.removeItem(LOGIN_REDIRECT);
    localStorage.removeItem(REDIRECT_ID);
  }, 3000);
};

export const LoginBaseHandle = React.memo(WithCoinList(WithFiatAndSpotTrade(BaseHandle)));
