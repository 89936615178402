/**
 * antd message 二次封装，修改icon
 */
import React from "react";
import { message as antMessage } from "antd";
import Image from "next/image";

const tips_red = require("@aspen/assets/images/tips_red.svg");
const tips_green = require("@aspen/assets/images/tips_green.svg");
const tips_blue = require("@aspen/assets/images/tips_blue.svg");

const getParams = (args, duration?, onClose?) => {
  let params = args;
  if (typeof args === "string") {
    params = { content: args };
    if (duration) {
      params.duration = duration;
    }
    if (onClose) {
      params.onClose = onClose;
    }
  }
  return params ?? { content: " ", duration, onClose };
};

const MessageIcon = ({ icon }) => {
  return <Image unoptimized alt="" width={15} height={15} src={icon} />;
};

const success = (args, duration?, onClose?) => {
  const params = getParams(args, duration, onClose);
  antMessage.destroy();
  return antMessage.success({
    ...params,
    icon: <MessageIcon icon={tips_green} />
  });
};

const error = (args, duration?, onClose?) => {
  const params = getParams(args, duration, onClose);
  antMessage.destroy();
  return antMessage.error({
    ...params,
    icon: <MessageIcon icon={tips_red} />
  });
};

const info = (args, duration?, onClose?) => {
  const params = getParams(args, duration, onClose);
  antMessage.destroy();
  return antMessage.warning({
    ...params,
    icon: <MessageIcon icon={tips_blue} />
  });
};

export const message = {
  ...antMessage,
  success,
  error,
  warning: info,
  info
};
