import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IResSaasWallet } from "@aspen/model";
import { RootState } from "../store";

interface InitialState {
  saasWalletInfo: IResSaasWallet | null;
}

const initialState: InitialState = {
  saasWalletInfo: null
};

export const saasWalletSlice = createSlice({
  name: "Wallet",
  initialState,
  reducers: {
    updateSaasWalletInfo: (state, action: PayloadAction<IResSaasWallet>) => {
      state.saasWalletInfo = action.payload;
    },
    clearSaasAllWallet: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    }
  }
});

export const saasWalletInfo = (state: RootState) => state.wallet.saasWalletInfo;

export const { updateSaasWalletInfo, clearSaasAllWallet } = saasWalletSlice.actions;

export default saasWalletSlice.reducer;
