"use client";
import React, { Component } from "react";

import { sendPhoneCode, sendPhoneCodeWithoutLogin } from "@aspen/services/index";
import { message } from "../customMessage";
import { i18nUtil, BEFORE_LOGIN_SENAD_EMAIL_PATH } from "@aspen/libs/index";
import type { IReqSendPhoneCode } from "@aspen/model/index";
import { IBindingBusinessType } from "@aspen/model/index";

export interface IPropsWithLoginCodeLayout {
  [key: string]: any; // 保留any，HOC组件，父组件传过来的props可能是任何类型
}

type IState = {
  countdown: number;
  loading: boolean;
  verifyStatus: boolean | string;
  interval: NodeJS.Timer | null;
  businessType: number;
};

export const LoginCodeLayout = (WrappedComponent) => {
  class WithLoginCodeLayout extends Component<IPropsWithLoginCodeLayout, IState> {
    constructor(props: IPropsWithLoginCodeLayout) {
      super(props);
      this.state = {
        loading: false,
        countdown: 60, // 倒计时
        verifyStatus: "", // 验证码验证结果
        interval: null,
        businessType: 0
      };
      this.getPhoneCode = this.getPhoneCode.bind(this);
    }

    getPhoneCode: (businessType: number, email: string, jwtToken?: string) => void = async (
      businessType,
      phone,
      jwtToken
    ) => {
      const intl = i18nUtil.t();
      // // 当用户点击发送验证码时，先保存用户邮箱，上报信息时使用
      // if (email && !sessionStorage.getItem(USER)) {
      //   sessionStorage.setItem(USER, email);
      // }

      this.setState({ loading: true });
      //登陆后可不传邮箱
      let params: IReqSendPhoneCode = {
        businessType,
        smsType: 0
      };
      if (phone) params.phone = phone;
      // 登陆后调用需要身份验证的验证码
      // 注册之后绑定手机业务，此时没有登录，IS_AUTHENTICATED为false
      const isLoginRoute = BEFORE_LOGIN_SENAD_EMAIL_PATH.some((e) => location.pathname.includes(e));
      const fetchMethod =
        !isLoginRoute || [IBindingBusinessType.bindPhone].includes(businessType)
          ? sendPhoneCode
          : sendPhoneCodeWithoutLogin;
      // @ts-ignore
      fetchMethod(params, { jwtToken })
        .then((res) => {
          if (res?.code == "0") {
            // 发送验证码60s之后才可以再次发送
            this.setState({
              interval: setInterval(() => {
                if (this.state.countdown <= 0) {
                  // @ts-ignore
                  clearInterval(this.state.interval);
                  this.setState({
                    countdown: 60
                  });
                } else {
                  this.setState({
                    countdown: this.state.countdown - 1
                  });
                }
              }, 1000)
            });
          } else {
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };

    render() {
      const intl = i18nUtil.t();
      const sendPhoneBtnTxt =
        this.state.countdown == 60
          ? intl["register.sendverify"]
          : `${intl["register.resend"]}(${this.state.countdown})`;
      return (
        <>
          <WrappedComponent
            {...this.props}
            getPhoneCode={this.getPhoneCode}
            countdown={this.state.countdown}
            sendPhoneBtn={sendPhoneBtnTxt}
            loading={this.state.loading}
          />
        </>
      );
    }
    componentWillUnmount(): void {
      // @ts-ignore
      clearInterval(this.state.interval);
    }
  }
  return WithLoginCodeLayout;
};
