import { LOGIN_PATHS } from "../config/index";
import { CUSTOMER_INFO, USER, KYC_CERTIFIED_LEVEL } from "../config/hosts/index";
import type {
  IFlexibleProducts,
  IKycLevel,
  IMemberEquities,
  IMemberEquity,
  ISSRRedirect,
  IWalletYieldAccount
} from "@aspen/model/index";
import { IVipType } from "@aspen/model/index";
import { isClient } from "./common";

/**
 * 给理财产品列表添加会员利率对比
 * @param list  需要展示会员APY的产品列表
 * @param memberEquities  享有会员APY的产品
 */
const handleProductsByMember = (
  list: IFlexibleProducts[] | IWalletYieldAccount[],
  memberEquities: IMemberEquities[]
): IFlexibleProducts[] | IWalletYieldAccount[] => {
  let copyList = list.slice();
  if (memberEquities.length) {
    copyList.map((item) => {
      memberEquities.forEach((data) => {
        if (item?.currency === data?.currency) {
          // @ts-ignore
          item.currentLevel = data?.level;
          // @ts-ignore
          item.memberEquitiesRate = data?.rate;
        }
      });
    });
  }
  return copyList;
};

const handleLevel = (level: string) => {
  let _level: string = IVipType.L0;
  switch (level) {
    case "L0":
      _level = IVipType.L0;
      break;
    case "L1":
      _level = IVipType.L1;
      break;
    case "L2":
      _level = IVipType.L2;
      break;
  }
  return _level;
};

/**
 * 将返回的会员权益信息处理成方便后续使用的格式
 * @param list
 * @return [{level,currency,rate}]
 * level： L0,L1,L2 ------> Silver,Gold,Black
 * currency：享有会员APY的币种
 * rate：会员APY值
 */
const handleMemberEquitiesData = (list: IMemberEquity[]): IMemberEquities[] => {
  let copyList = list.slice();
  let _memberEquities: IMemberEquities[] = [];
  copyList.forEach((item: IMemberEquity) => {
    if (item.params.indexOf(",") !== -1) {
      const currency = item.params?.split(",")[0];
      const rate = item.params?.split(",")[1];
      _memberEquities.push({ level: handleLevel(item.level), currency, rate });
    }
  });
  return _memberEquities;
};

// 服务端渲染返回未登录时跳转至对应角色的登录页面
const ssrToLogin: (domain: string) => { redirect: ISSRRedirect } = (domain) => {
  // 统一都是登录页面
  const _loginPath: string = LOGIN_PATHS.LOGIN;
  return {
    redirect: {
      destination: _loginPath,
      permanent: false
    }
  };
};

// 返回当前用户名，kycName || registerName || currentEmail
const getCurrentUserName: () => string = () => {
  const customerInfo = isClient && sessionStorage.getItem(CUSTOMER_INFO);
  const kycName: string = (customerInfo && JSON.parse(customerInfo)?.kycName) ?? "";
  const registerName: string = (customerInfo && JSON.parse(customerInfo)?.registerName) ?? "";
  const currentEmail: string = (isClient && sessionStorage.getItem(USER)) || "";
  return kycName || registerName || currentEmail;
};

// 判断该场景kyc等级是否满足， level目前参数为1｜2
const certifiedKycLevel: (level: IKycLevel) => boolean = (level) => {
  const kycLevel = isClient && localStorage.getItem(KYC_CERTIFIED_LEVEL)?.replace(/[^0-9]/gi, "");
  if (Number(kycLevel) >= level) {
    return true;
  }
  return false;
};

/**
 * @description: 根据日期获取当前年龄 YYYY-MM-DD
 * @returns {string}
 */
const getAge: (params: string) => number = (params) => {
  //出生时间 毫秒
  let birthDayTime = new Date(params).getTime();
  //当前时间 毫秒
  let nowTime = new Date().getTime();
  //一年毫秒数(365 * 86400000 = 31536000000)
  return Math.ceil((nowTime - birthDayTime) / 31536000000) - 1;
};

export {
  handleProductsByMember,
  handleMemberEquitiesData,
  ssrToLogin,
  handleLevel,
  getCurrentUserName,
  certifiedKycLevel,
  getAge
};
