import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ICurrency } from "@aspen/model/index";

interface ICoinList {
  [key: string]: ICurrency;
}
export interface IState {
  coinList: ICoinList;
}

export const currencies = (state: RootState) => state.currencies.coinList;

const initialState: IState = {
  coinList: {}
};
// TODO
export const currenciesSlice: any = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    updateCoinList: (state, action: PayloadAction<{ coinList: ICoinList }>) => {
      state.coinList = action.payload.coinList;
    }
  }
});

export const { updateCoinList } = currenciesSlice.actions;

export default currenciesSlice.reducer;
